import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import styles from '../../../therapy-tests/index.module.scss';
import moment from 'moment';

const columns = [
  { id: 'date', label: 'Дата платежу' },
  { id: 'amount', label: 'Сума платежу' },
  { id: 'externalId', label: 'external Id' },
  { id: 'status', label: 'Статус' },
  { id: 'externalStatus', label: 'external status'},
];

const PaymentTransactionsTable = ({ transactions }) => {
  const handleCellView = (value, columnType, transaction) => {
    switch (columnType) {
      case 'date': return moment(new Date(value)).locale('uk').format('LL');
      case 'status': return ["Помилкка", 'Очікується', 'Зараховано', 'Розділено'][value.val];
      default: return value;
    };
  };

  return (
    <TableContainer sx={{width: '100%', position: 'relative', marginBottom: '20px', marginTop: '10px'}}>
      <Table stickyHeader aria-labelledby="tableTitle" size={"small"}>
        <TableHead>
          <TableRow>
            {transactions.length > 0 && columns.map((item) => (
              <TableCell
                key={item.id}
                align="left"
                style={{ fontWeight: 'bold'}}
              >
                {item.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions?.map((row, index) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={index.toString()}
              >
                {columns?.map((column) => {
                  return (
                    <TableCell key={column.id} align="left" className={styles.rowItemContainer}>
                      {handleCellView(row[column.id], column.id)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default PaymentTransactionsTable;