import Limbo from 'limbojs';
import authService from '../authService';
const limboClient = new Limbo();
var url = '/limbo';
//const url = 'http://localhost/limbo';

limboClient.delegate({regExp : /^[^_].+/, handle : query => {
  const sessionId = localStorage?.getItem('sid');

  let headers = {
    "Content-Type" : "application/json"
  };

  !!sessionId && authService.isDevMode() && (headers.Authorization = "Bearer "+sessionId)

  return fetch(url, {
    method : "POST",
    headers : headers,
    body : JSON.stringify({query : query})
  }).then(resp => {
    return resp && resp.json();
  }).catch(err => {
    console.log(err)
  });
}});

export default limboClient;