import React, { useEffect, useState } from "react";
import {
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import client from "../../../../services/core/limboclient";

const columns = [
  { id: "rate", label: "Тариф" },
  { id: "createDate", label: "Дата Створення" },
  { id: "scheduled", label: "Запланована Дата" },
  { id: "status", label: "Статус" },
];

const statuses = {
  canceled: "0",
  completed: "1",
  planned: "2",
};

const SessionTab = ({ allianceID }) => {
  const [sessions, setSessions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState({
    isOpen: false,
    alertType: "success",
    message: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [sessionForm, setSessionForm] = useState(null);

  const getSessions = () => {
    if (allianceID) {
      client
        .call(
          `=>client_getMySessions~ {
            "alliance": "${allianceID}"
          }`
        )
        .then((result) => {
          if (Array.isArray(result)) {
            setSessions(result);
          } else {
            setSessions([]);
          }
        });
    }
  };

  const handleCellView = (value, columnType) => {
    switch (columnType) {
      case "createDate":
      case "scheduled":
        return value ? new Date(value).toLocaleDateString() : "";
      case "status":
        return value?.displayVal || "";
      default:
        return value;
    }
  };

  const deleteSession = (id) => {
    client.call(`=>client_deleteSession ~ {"session": "${id}"}`).then((res) => {
      if (res.success === true) {
        setOpenSnackbar({
          isOpen: true,
          alertType: "success",
          message: "Сесію було успішно видалено!",
        });
        getSessions();
      } else {
        setOpenSnackbar({
          isOpen: true,
          alertType: "error",
          message: "Сесію не було видалено, спробуйте ще раз!",
        });
      }
    });
  };

  const handleDialogOpen = (row) => {
    if (row) {
      setSelectedSession(row);
      setSessionForm({ ...row });
    } else {
      setSelectedSession(null);
      setSessionForm({
        rate: "",
        scheduled: "",
        status: { val: "", displayVal: "" },
      });
    }
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedSession(null);
    setSessionForm(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSessionForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveSession = () => {
    const sessionData = {
      status: sessionForm.status.val.toString(),
      scheduled: new Date(sessionForm.scheduled).toLocaleDateString(),
      rate: sessionForm.rate,
    };

    if (selectedSession?.id) {
      client
        .call(
          `=>client_updateSession~ ${JSON.stringify({
            id: selectedSession.id,
            ...sessionData,
          })}`
        )
        .then((res) => {
          if (res.success === true) {
            setOpenSnackbar({
              isOpen: true,
              alertType: "success",
              message: "Сесію було успішно оновлено!",
            });
            getSessions();
            handleDialogClose();
          } else {
            setOpenSnackbar({
              isOpen: true,
              alertType: "error",
              message: "Сесію не було оновлено, спробуйте ще раз!",
            });
          }
        });
    } else {
      client
        .call(
          `=>client_createSession~ ${JSON.stringify({
            alliance: allianceID,
            ...sessionData,
          })}`
        )
        .then((res) => {
          console.log(res);
          if (res.success) {
            setOpenSnackbar({
              isOpen: true,
              alertType: "success",
              message: "Сесію було успішно створено!",
            });
            getSessions();
            handleDialogClose();
          } else {
            setOpenSnackbar({
              isOpen: true,
              alertType: "error",
              message: "Сесію не було створено, спробуйте ще раз!",
            });
          }
        });
    }
  };

  useEffect(() => {
    getSessions();
  }, [allianceID]);

  return (
    <div className='border border-[#a1a1a1] rounded-lg p-5 w-full mt-5'>
      <div className='flex items-center justify-between'>
        <p className='font-bold text-xl'>Сесії</p>
        <Button
          onClick={() => handleDialogOpen(null)}
          variant='contained'
        >
          Створити сесію
        </Button>
      </div>
      <TableContainer
        sx={{
          width: "100%",
          position: "relative",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        <Table
          stickyHeader
          aria-labelledby='tableTitle'
          size={"small"}
        >
          <TableHead>
            <TableRow>
              {columns.map((item) => (
                <TableCell
                  key={item.id}
                  align='left'
                  style={{ fontWeight: "bold" }}
                >
                  {item.label}
                </TableCell>
              ))}
              <TableCell
                key='action'
                align='right'
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions?.map((row, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index.toString()}
                  onClick={() => handleDialogOpen(row)}
                  style={{ cursor: "pointer" }}
                >
                  {columns?.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align='left'
                      >
                        {handleCellView(value, column.id)}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    key='edit'
                    align='right'
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Tooltip title={`Видалити сесію "${row.name}"`}>
                      <DeleteForeverTwoToneIcon
                        color='error'
                        className='ml-2 cursor-pointer'
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteSession(row.id);
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {sessions?.length === 0 && (
          <em className='opacity-70 text-xl mt-5 flex justify-center'>
            Сесії відсутні...
          </em>
        )}
        <Snackbar
          open={openSnackbar.isOpen}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
        >
          <MuiAlert
            onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
            severity={openSnackbar.alertType}
            sx={{ width: "100%" }}
          >
            {openSnackbar.message}
          </MuiAlert>
        </Snackbar>
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          {selectedSession ? "Редагувати Сесію" : "Створити Сесію"}
        </DialogTitle>
        <DialogContent dividers>
          {sessionForm && (
            <div>
              <TextField
                label='Тариф'
                value={sessionForm.rate}
                name='rate'
                onChange={handleInputChange}
                fullWidth
                margin='normal'
              />
              <TextField
                label='Запланована дата'
                value={
                  sessionForm.scheduled
                    ? new Date(sessionForm.scheduled)
                        .toISOString()
                        .substring(0, 10)
                    : ""
                }
                name='scheduled'
                onChange={handleInputChange}
                fullWidth
                margin='normal'
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControl
                fullWidth
                margin='normal'
              >
                <InputLabel id='status-label'>Статус</InputLabel>
                <Select
                  labelId='status-label'
                  id='status'
                  value={sessionForm.status?.val.toString() || ""}
                  name='status'
                  label='Статус'
                  onChange={(e) => {
                    const value = e.target.value;
                    setSessionForm((prevState) => ({
                      ...prevState,
                      status: {
                        val: value,
                        displayVal: statuses[value],
                      },
                    }));
                  }}
                >
                  <MenuItem value={0}>Відмінена</MenuItem>
                  <MenuItem value={1}>Відбулась</MenuItem>
                  <MenuItem value={2}>Запланована</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Закрити</Button>
          <Button
            variant='contained'
            onClick={saveSession}
          >
            {selectedSession ? "Зберегти зміни" : "Створити Сесію"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SessionTab;
