import limboClient from './core/limboclient';
import authService from './authService';

const getAllTests = (navigateToSignIn) => {
	return limboClient.call(`=>therapyTest_getTestList~`).then(result => {
		if (result.success === false && result.error === 'not_authorized') {
			authService.logout(navigateToSignIn);
			return;
		}

		return result;
	});
};

const createTest = (props) => {
	const params = {
		id: 'new',
		name: props.name,
		details: props?.details ?? '',
		questions: props?.questions ?? [],
		isPublic: props?.isPublic,
		daysToReload: props?.daysToReload
	};

	return limboClient.call(`=>therapyTest_create~${JSON.stringify(params)}`).then(result => {
		if (result.success === false && result.error === 'not_authorized') {
			authService.logout();
		}
	});
};

const updateTest = (config, navigateToSignIn) => {
	return limboClient.call(`=>therapyTest_editTest~${JSON.stringify(config)}`).then(result => {
		if (result.success === false && result.error === 'not_authorized') {
			authService.logout(navigateToSignIn);
		}
	});
};

const deleteTest = (testId, navigateToSignIn) => {
	return limboClient.call(`=>therapyTest_delete~${JSON.stringify({id: testId})}`).then(result => {
		if (result.success === false && result.error === 'not_authorized') {
			authService.logout(navigateToSignIn);
		}
	});
};

const getTestDetails = (testId, navigateToSignIn) => {
	return limboClient.call(`=>therapyTest_getTestDetails ~${JSON.stringify({ id: testId })}`).then(result => {
		if (result.success === false && result.error === 'not_authorized') {
			authService.logout(navigateToSignIn);
			return;
		}

		return result;
	});
};

export default {
	getAllTests,
	getTestDetails,
	createTest,
	deleteTest,
	updateTest
}