import limboClient from './core/limboclient';
import client from './core/limboclient';

const login = (email, password, handleAfterLogin) => {
	const inviteId = localStorage.getItem('inviteId');

	return limboClient.call(`=>user_login ~${JSON.stringify({ email, password, ...(!!inviteId && { isClient: true }) })}`).then(result => {
		if(result.success === true || (result.success === false && result.error === 'user_is_already_logged_in')) {
			if (isDevMode()) localStorage.setItem('isAuth', 'true');
			client.call('=>user_getMyProfile~').then(result => {
				if (!!result.first_name || !!result.last_name) {
					localStorage.setItem('firstName', result.first_name);
					localStorage.setItem('lastName', result.last_name);
					handleAfterLogin()
				}
			});
		}

		if (inviteId) {
			client.call(`=>client_assignByInvite~${JSON.stringify({ invite: inviteId })}`).then(result => {
				if (result.success) {
					localStorage.removeItem('inviteId');
				}
			});
		}

		return result;
	});
};

const sendConfirmCode = async (email) => {
	return await limboClient.call(`=>user_sendConfirm~${JSON.stringify({email: email})}`).then(result => {
		return result;
	});
};

const logout = (navigateToSignIn) => {
	if (isDevMode()) {
		localStorage.removeItem('isAuth');
		localStorage.removeItem('sid');
	}

	limboClient.call(`=>user_logout~`).then(result => {
		if (result.success) {
			navigateToSignIn()
		}
	});

	navigateToSignIn();
};

const updateSessionId = () => {
	if (isDevMode()) limboClient.call(`=>user_getSID~`).then(result => localStorage.setItem('sid', result));
};

const getDashboardList = (navigateToSignIn) => {
	return limboClient.call(`=>user_getDashboard~`).then(result => {
		if (result.success === false && result.error === 'not_authorized') {
			logout(navigateToSignIn);
			return;
		}

		return result
	});
};

const isAuthorizedUser = async () => {
	return await limboClient.call(`=>user_isAuthorized~`).then(result => {
		return result
	});
};

const isDevMode = () => {
	return window.location.hostname === 'localhost' && window.location.port === '3000';
};

const googleLogin = (jwt, handleAfterLogin) => {
	limboClient.call({
		query : `=>user_login ~ {"type" : "google", "jwt" : $jwt }`,
		params : { jwt: jwt }
	}).then(result => {
		if(result.success === true || (result.success === false && result.error === 'user_is_already_logged_in')) {
			if (isDevMode()) localStorage.setItem('isAuth', 'true');
			handleAfterLogin();
		}
	});
};

export default {
	logout,
	updateSessionId,
	login,
	getDashboardList,
	isAuthorizedUser,
	isDevMode,
	googleLogin,
	sendConfirmCode
}