import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  TextField,
  Button,
  Snackbar,
  Tooltip,
} from "@mui/material";
import limboClient from "../../../../services/core/limboclient";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ReactComponent as ViberIcon } from "../../../../assets/viber.svg";
import { ReactComponent as TelegramIcon } from "../../../../assets/telegram.svg";
import { ReactComponent as WhatsAppIcon } from "../../../../assets/whatsApp.svg";

const availableStatusView = [
  { id: 1, label: "Active" },
  { id: 2, label: "Await" },
];

export const convertToDateFormat = (awaitTill) => {
  const year = parseInt(awaitTill?.slice(0, 2));
  const month = parseInt(awaitTill?.slice(2));

  const currentYear = moment().year();
  const fullYear = currentYear - (currentYear % 100) + year;
  const date = moment({ year: fullYear, month: month - 1 });

  return date.format("YYYY.MM");
};

const initialClientState = {
  id: "",
  name: "",
  phone: "",
  email: "",
  rate: "",
  therapistShare: "",
  referrer: "",
  howToCall: { val: "" },
  awaitTill: "",
  createDate: "",
  notes: [],
  alliances: {
    therapist: {
      specialistShare: null,
      rate: null,
      specialist: "", // id
      specialistType: {
        val: 0,
        displayVal: "therapist",
      },
      status: {
        val: 2,
        displayVal: "Await",
      },
      awaitTill: null,
    },
    consultant: {
      specialistShare: null,
      rate: null,
      specialist: "", // id
      specialistType: {
        val: 1,
        displayVal: "consultant",
      },
      status: {
        val: 1,
        displayVal: "Active",
      },
      awaitTill: null,
    },
  },
};

const ClientModal = ({
  isOpen,
  onClose,
  selectedClientId,
  therapistList,
  consultantList,
}) => {
  const [initialClient, setInitialClient] = useState(initialClientState);
  const [client, setClient] = useState(initialClientState);
  const [newNote, setNewNote] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState({
    isOpen: false,
    alertType: "success",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen && selectedClientId) {
      limboClient
        .call(
          `=> client_getClient ~ ${JSON.stringify({ id: selectedClientId })}`
        )
        .then((result) => {
          const alliances = {
            therapist:
              result.alliances.find(
                (alliance) => alliance.specialistType.val === 0
              ) || initialClientState.alliances.therapist,
            consultant:
              result.alliances.find(
                (alliance) => alliance.specialistType.val === 1
              ) || initialClientState.alliances.consultant,
          };

          const clientData = {
            ...result,
            alliances,
          };
          setClient(clientData);
          setInitialClient(clientData);
          setIsLoading(false);
        });
    }
  }, [selectedClientId, isOpen]);

  const handleTopLevelFieldBlur = (field) => {
    if (client[field] !== initialClient[field]) {
      handleUpdateClient(client);
      setInitialClient({ ...initialClient, [field]: client[field] });
    }
  };

  const handleNestedFieldBlur = (allianceType, field) => {
    if (
      client.alliances[allianceType][field] !==
      initialClient.alliances[allianceType][field]
    ) {
      handleUpdateClient(client);
      setInitialClient({
        ...initialClient,
        alliances: {
          ...initialClient.alliances,
          [allianceType]: {
            ...initialClient.alliances[allianceType],
            [field]: client.alliances[allianceType][field],
          },
        },
      });
    }
  };

  const handleClose = () => {
    setClient(initialClientState);
    setIsLoading(true);
    onClose();
  };

  const handleStatusView = (status) => {
    const statusViews = {
      active: (
        <Chip
          label={status}
          color='primary'
        />
      ),
      await: (
        <Chip
          label={status}
          color='warning'
        />
      ),
    };
    return statusViews[status?.toLowerCase()] || null;
  };

  const handleAssignClient = (type, specialistId) => {
    const apiCalls = {
      therapist: () =>
        limboClient.call(
          `=> client_assignClientToTherapist ~ ${JSON.stringify({
            client: client.id,
            therapist: specialistId,
          })}`
        ),
      consultant: () =>
        limboClient.call(
          `=> client_assignClientToConsultant ~ ${JSON.stringify({
            client: client.id,
            consultant: specialistId,
          })}`
        ),
    };

    apiCalls[type]().then((result) => {
      if (result.success) {
        setOpenSnackbar({
          isOpen: true,
          alertType: "success",
          message: "Дані клієнта було успішно збережено!",
        });
      } else {
        setOpenSnackbar({
          isOpen: true,
          alertType: "error",
          message: "Дані клієнта не було збережено, спробуйте ще раз!",
        });
      }
      setClient({
        ...client,
        alliances: {
          ...client.alliances,
          [type]: {
            ...client.alliances[type],
            specialist: specialistId,
          },
        },
      });
    });
  };

  const handleDeleteSpecialist = (type) => {
    limboClient
      .call(
        `=> client_unassignClient ~ ${JSON.stringify({
          client: client.id,
          from: type,
        })}`
      )
      .then((result) => {
        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Дані клієнта було успішно збережено!",
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Дані клієнта не було збережено, спробуйте ще раз!",
          });
        }
        setClient({
          ...client,
          alliances: {
            ...client.alliances,
            [type]: initialClientState.alliances[type],
          },
        });
      });
  };

  const handleUpdateClient = async (updatedClient) => {
    const { alliances } = updatedClient;
    await limboClient
      .call(
        `=> client_updateClient ~ ${JSON.stringify({
          client: updatedClient.id,
          set: {
            status: updatedClient.status?.val,
            therapistShare: updatedClient.therapistShare,
            phone: updatedClient.phone,
            rate: updatedClient.rate,
            referrer: updatedClient.referrer,
            email: updatedClient.email,
            alliances: {
              therapist: {
                status: alliances.therapist.status.val,
                specialistShare: alliances.therapist.specialistShare,
                rate: alliances.therapist.rate,
                awaitTill: alliances.therapist.awaitTill,
              },
              consultant: {
                status: alliances.consultant.status.val,
                specialistShare: alliances.consultant.specialistShare,
                rate: alliances.consultant.rate,
                awaitTill: alliances.consultant.awaitTill,
              },
            },
          },
        })}`
      )
      .then((result) => {
        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Дані клієнта було успішно збережено!",
          });
          setInitialClient(updatedClient);
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Дані клієнта не було збережено, спробуйте ще раз!",
          });
        }
      });
    setClient(updatedClient);
  };

  const handleAddNewNote = async () => {
    if (newNote.length > 0) {
      await limboClient
        .call(
          `=> client_addNote ~ ${JSON.stringify({
            client: client.id,
            note: newNote,
          })}`
        )
        .then((result) => {
          if (result.success) {
            setOpenSnackbar({
              isOpen: true,
              alertType: "success",
              message: "Нотатка була успішно додана!",
            });
            setNewNote("");
          } else {
            setOpenSnackbar({
              isOpen: true,
              alertType: "error",
              message: "Нотатка не була додана, спробуйте ще раз!",
            });
          }
        });

      const updatedClient = await limboClient.call(
        `=> client_getClient ~ ${JSON.stringify({ id: selectedClientId })}`
      );
      const alliances = {
        therapist:
          updatedClient.alliances.find(
            (alliance) => alliance.specialistType.val === 0
          ) || initialClientState.alliances.therapist,
        consultant:
          updatedClient.alliances.find(
            (alliance) => alliance.specialistType.val === 1
          ) || initialClientState.alliances.consultant,
      };

      setClient({
        ...updatedClient,
        alliances,
      });
    } else {
      setOpenSnackbar({
        isOpen: true,
        alertType: "error",
        message: "Нотатка не була додана, спробуйте ще раз!",
      });
    }
  };

  const handleMessengerView = (howToCall) => {
    const messengers = {
      viber: (
        <div className='flex items-center'>
          <p>Viber</p>
          <ViberIcon className='h-[30px]' />
        </div>
      ),
      telegram: (
        <div className='flex items-center'>
          <p>Telegram</p>
          <TelegramIcon className='h-[30px]' />
        </div>
      ),
      whatsUp: (
        <div className='flex items-center'>
          <p>WhatsApp</p>
          <WhatsAppIcon className='h-[35px]' />
        </div>
      ),
    };
    return messengers[howToCall] || howToCall;
  };

  const handleDeleteNote = async (noteId) => {
    limboClient
      .call(
        `=>note_delete ~ ${JSON.stringify({
          id: noteId,
        })}`
      )
      .then((result) => {
        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Нотатка була успішно видалена!",
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Нотатка не була видалена, спробуйте ще раз!",
          });
        }
      });

    const updatedClient = await limboClient.call(
      `=> client_getClient ~ ${JSON.stringify({ id: selectedClientId })}`
    );
    const alliances = {
      therapist:
        updatedClient.alliances.find(
          (alliance) => alliance.specialistType.val === 0
        ) || initialClientState.alliances.therapist,
      consultant:
        updatedClient.alliances.find(
          (alliance) => alliance.specialistType.val === 1
        ) || initialClientState.alliances.consultant,
    };

    setClient({
      ...updatedClient,
      alliances,
    });
  };

  return (
    <Dialog
      open={isOpen && !isLoading}
      onClose={handleClose}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle className='text-center !font-bold'>
        Редагування клієнта
      </DialogTitle>
      <DialogContent>
        <p className='font-bold'>{client?.name}</p>
        <div className='flex items-center mt-5'>
          <p className='font-bold mr-12'>Телефон:</p>
          <TextField
            variant='outlined'
            placeholder='Телефон...'
            size='small'
            type='phone'
            value={client?.phone || ""}
            onChange={(event) =>
              setClient({ ...client, phone: event.target.value })
            }
            onBlur={() => handleTopLevelFieldBlur("phone")}
            className='w-[200px] col-span-3'
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
            }}
          />
        </div>
        <div className='flex items-center mt-5'>
          <p className='font-bold mr-12'>Email:</p>
          <TextField
            variant='outlined'
            placeholder='Email...'
            size='small'
            type='text'
            value={client?.email || ""}
            onChange={(event) =>
              setClient({ ...client, email: event.target.value })
            }
            onBlur={() => handleTopLevelFieldBlur("email")}
            className='w-[200px] col-span-3'
          />
        </div>
        <div className='flex items-center mt-5'>
          <p className='font-bold mr-6 max-w-[100px]'>Як про нас дізнались:</p>
          <TextField
            variant='outlined'
            placeholder='Як про нас дізнались...'
            size='small'
            value={client?.referrer || ""}
            onChange={(event) =>
              setClient({ ...client, referrer: event.target.value })
            }
            onBlur={() => handleTopLevelFieldBlur("referrer")}
            className='w-[200px] col-span-3'
          />
        </div>
        <div className='flex items-center mt-5'>
          <p className='font-bold mr-16 max-w-[58px]'>
            Обраний спосіб комунікації:
          </p>
          {handleMessengerView(client?.howToCall?.displayVal)}
        </div>
        <div className='flex flex-col md:flex-row md:space-x-6 mt-5'>
          {/* Therapist Section */}
          <div className='flex-1'>
            <p className='font-bold'>Терапевт</p>
            <div className='flex items-center mt-5'>
              <p className='font-bold mr-16'>Статус:</p>
              <FormControl
                variant='standard'
                key='select-therapist-status'
                className='w-[150px]'
              >
                <Select
                  value={client?.alliances.therapist?.status.val || ""}
                  input={<OutlinedInput />}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className='!w-full !bg-white'
                  renderValue={() => {
                    if (!client?.alliances.therapist?.status.val)
                      return <em className='opacity-70'>Оберіть статус</em>;
                    return (
                      <p className='absolute top-[20%]'>
                        {handleStatusView(
                          availableStatusView.find(
                            (item) =>
                              item.id ===
                              Number(client?.alliances.therapist.status.val)
                          )?.label
                        ) ?? ""}
                      </p>
                    );
                  }}
                  onChange={(event) =>
                    handleUpdateClient({
                      ...client,
                      alliances: {
                        ...client.alliances,
                        therapist: {
                          ...client.alliances.therapist,
                          status: {
                            ...client.alliances.therapist.status,
                            val: Number(event.target.value),
                          },
                        },
                      },
                    })
                  }
                >
                  {availableStatusView.map((status) => (
                    <MenuItem
                      value={status.id.toString()}
                      key={status.id}
                    >
                      {handleStatusView(status.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='flex items-center mt-5'>
              <p className='font-bold'>Очікує до:</p>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label='Очікує до:'
                  value={moment(
                    convertToDateFormat(
                      client.alliances.therapist?.awaitTill?.toString()
                    )
                  )}
                  views={["month", "year"]}
                  onChange={(newValue) =>
                    handleUpdateClient({
                      ...client,
                      alliances: {
                        ...client.alliances,
                        therapist: {
                          ...client.alliances.therapist,
                          awaitTill: moment(newValue).format("YYMM"),
                        },
                      },
                    })
                  }
                  className='w-[320px] !ml-10'
                  format='YYYY.MM'
                />
              </LocalizationProvider>
            </div>
            <div className='flex items-center mt-5'>
              <p className='font-bold mr-10'>Терапевт:</p>
              <FormControl
                variant='standard'
                key='select-therapist'
                className='w-full'
              >
                <Select
                  value={client?.alliances.therapist?.specialist || ""}
                  input={<OutlinedInput />}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className='!w-full !bg-white'
                  renderValue={() => {
                    if (!client?.alliances.therapist?.specialist)
                      return <em className='opacity-70'>Оберіть терапевта</em>;
                    const therapist = therapistList.find(
                      (item) =>
                        item.id === client?.alliances.therapist?.specialist
                    );
                    return (
                      <p className='grid grid-cols-2 gap-y-2 gap-x-2'>
                        {therapist?.firstName} {therapist?.lastName}
                      </p>
                    );
                  }}
                  onChange={(event) =>
                    handleAssignClient("therapist", event.target.value)
                  }
                >
                  {therapistList.map((therapist) => (
                    <MenuItem
                      value={therapist.id}
                      key={therapist.id}
                      className='grid grid-cols-2 gap-y-2 gap-x-2'
                    >
                      {therapist.firstName} {therapist.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {client?.alliances.therapist?.specialist && (
                <ClearTwoToneIcon
                  color='error'
                  className='cursor-pointer ml-5'
                  onClick={() => handleDeleteSpecialist("therapist")}
                />
              )}
            </div>
            <div className='flex items-center mt-5'>
              <p className='font-bold mr-16'>Тариф:</p>
              <TextField
                variant='outlined'
                placeholder='Тариф...'
                size='small'
                type='number'
                value={client?.alliances.therapist?.rate || ""}
                onChange={(event) =>
                  setClient({
                    ...client,
                    alliances: {
                      ...client.alliances,
                      therapist: {
                        ...client.alliances.therapist,
                        rate: event.target.value,
                      },
                    },
                  })
                }
                onBlur={() => handleNestedFieldBlur("therapist", "rate")}
                className='w-[200px] col-span-3'
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </div>
            <div className='flex items-center mt-5'>
              <p className='font-bold mr-16 w-[58px]'>Доля терапевта:</p>
              <TextField
                variant='outlined'
                placeholder='Доля терапевта...'
                size='small'
                type='number'
                value={client?.alliances.therapist?.specialistShare || ""}
                onChange={(event) =>
                  setClient({
                    ...client,
                    alliances: {
                      ...client.alliances,
                      therapist: {
                        ...client.alliances.therapist,
                        specialistShare: event.target.value,
                      },
                    },
                  })
                }
                onBlur={() =>
                  handleNestedFieldBlur("therapist", "specialistShare")
                }
                className='w-[200px] col-span-3'
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </div>
          </div>
          {/* Consultant Section */}
          <div className='flex-1'>
            <p className='font-bold'>Консультант</p>
            <div className='flex items-center mt-5'>
              <p className='font-bold mr-16'>Статус:</p>
              <FormControl
                variant='standard'
                key='select-consultant-status'
                className='w-[150px]'
              >
                <Select
                  value={client?.alliances.consultant?.status.val || ""}
                  input={<OutlinedInput />}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className='!w-full !bg-white'
                  renderValue={() => {
                    if (!client?.alliances.consultant?.status.val)
                      return <em className='opacity-70'>Оберіть статус</em>;
                    return (
                      <p className='absolute top-[20%]'>
                        {handleStatusView(
                          availableStatusView.find(
                            (item) =>
                              item.id ===
                              Number(client?.alliances.consultant?.status.val)
                          )?.label
                        ) ?? ""}
                      </p>
                    );
                  }}
                  onChange={(event) =>
                    handleUpdateClient({
                      ...client,
                      alliances: {
                        ...client.alliances,
                        consultant: {
                          ...client.alliances.consultant,
                          status: {
                            ...client.alliances.consultant.status,
                            val: Number(event.target.value),
                          },
                        },
                      },
                    })
                  }
                >
                  {availableStatusView.map((status) => (
                    <MenuItem
                      value={status.id.toString()}
                      key={status.id}
                    >
                      {handleStatusView(status.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='flex items-center mt-5'>
              <p className='font-bold'>Очікує до:</p>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label='Очікує до:'
                  value={moment(
                    convertToDateFormat(
                      client.alliances.consultant?.awaitTill?.toString()
                    )
                  )}
                  views={["month", "year"]}
                  onChange={(newValue) =>
                    handleUpdateClient({
                      ...client,
                      alliances: {
                        ...client.alliances,
                        consultant: {
                          ...client.alliances.consultant,
                          awaitTill: moment(newValue).format("YYMM"),
                        },
                      },
                    })
                  }
                  className='w-[320px] !ml-10'
                  format='YYYY.MM'
                />
              </LocalizationProvider>
            </div>
            <div className='flex items-center mt-5'>
              <p className='font-bold mr-10'>Консультант:</p>
              <FormControl
                variant='standard'
                key='select-consultant'
                className='w-full'
              >
                <Select
                  value={client?.alliances.consultant?.specialist || ""}
                  input={<OutlinedInput />}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className='!w-full !bg-white'
                  renderValue={() => {
                    if (!client?.alliances.consultant?.specialist)
                      return (
                        <em className='opacity-70'>Оберіть консультанта</em>
                      );
                    const consultant = consultantList.find(
                      (item) =>
                        item.id === client?.alliances.consultant?.specialist
                    );
                    return (
                      <p className='grid grid-cols-2 gap-y-2 gap-x-2'>
                        {consultant?.firstName} {consultant?.lastName}
                      </p>
                    );
                  }}
                  onChange={(event) =>
                    handleAssignClient("consultant", event.target.value)
                  }
                >
                  {consultantList.map((consultant) => (
                    <MenuItem
                      value={consultant.id}
                      key={consultant.id}
                      className='grid grid-cols-2 gap-y-2 gap-x-2'
                    >
                      {consultant.firstName} {consultant.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {client?.alliances.consultant?.specialist && (
                <ClearTwoToneIcon
                  color='error'
                  className='cursor-pointer ml-5'
                  onClick={() => handleDeleteSpecialist("consultant")}
                />
              )}
            </div>
            <div className='flex items-center mt-5'>
              <p className='font-bold mr-16'>Тариф:</p>
              <TextField
                variant='outlined'
                placeholder='Тариф...'
                size='small'
                type='number'
                value={client?.alliances.consultant?.rate || ""}
                onChange={(event) =>
                  setClient({
                    ...client,
                    alliances: {
                      ...client.alliances,
                      consultant: {
                        ...client.alliances.consultant,
                        rate: event.target.value,
                      },
                    },
                  })
                }
                onBlur={() => handleNestedFieldBlur("consultant", "rate")}
                className='w-[200px] col-span-3'
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </div>
            <div className='flex items-center mt-5'>
              <p className='font-bold mr-16 w-[58px]'>Доля консультанта:</p>
              <TextField
                variant='outlined'
                placeholder='Доля консультанта...'
                size='small'
                type='number'
                value={client?.alliances.consultant?.specialistShare || ""}
                onChange={(event) =>
                  setClient({
                    ...client,
                    alliances: {
                      ...client.alliances,
                      consultant: {
                        ...client.alliances.consultant,
                        specialistShare: event.target.value,
                      },
                    },
                  })
                }
                onBlur={() =>
                  handleNestedFieldBlur("consultant", "specialistShare")
                }
                className='w-[200px] col-span-3'
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <p className='font-bold mb-2'>Нотатки:</p>
          {client?.notes?.length > 0 &&
            client.notes.map((item, index) => (
              <div
                key={index}
                className='border border-[#a1a1a1] p-2 rounded-lg relative mb-3'
              >
                <Tooltip
                  title='Видалити нотатку'
                  placement='top-end'
                >
                  <CloseIcon
                    color='error'
                    className='absolute top-2 right-2 cursor-pointer'
                    onClick={() => {
                      handleDeleteNote(item.id);
                    }}
                  />
                </Tooltip>
                <p className='w-[90%]'>{item.note}</p>
                <p className='text-sm text-gray flex items-center justify-end mt-2'>
                  {moment(new Date(item.createDate)).locale("uk").format("LL")}
                </p>
              </div>
            ))}
          {newNote !== null && (
            <TextField
              value={newNote}
              placeholder='Нова нотатка...'
              variant='outlined'
              size='small'
              onChange={(event) => setNewNote(event.target.value)}
              className='w-[100%] !rounded-lg'
            />
          )}
          <Button
            variant='contained'
            className='!mt-5'
            onClick={handleAddNewNote}
          >
            Додати нотатку
          </Button>
        </div>
        <Snackbar
          open={openSnackbar.isOpen}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
        >
          <MuiAlert
            onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
            severity={openSnackbar.alertType}
            sx={{ width: "100%" }}
          >
            {openSnackbar.message}
          </MuiAlert>
        </Snackbar>
      </DialogContent>
    </Dialog>
  );
};

export default ClientModal;
