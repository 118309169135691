import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import limboclient from "../../../../services/core/limboclient";
import authService from "../../../../services/authService";
import moment from "moment";

const availablePeriodUnit = [
  { value: 1, label: "День" },
  { value: 2, label: "Тиждень" },
];

const AssignmentModal = ({ isOpen, close, clientId, updatedAssignment }) => {
  const [assignment, setAssignment] = useState({
    name: "",
    firstDate: null,
    description: "",
    period: 1,
    moveIfNotCompleted: false,
    periodUnit: 1,
  });

  useEffect(() => {
    if (isOpen && updatedAssignment) {
      setAssignment({
        ...updatedAssignment,
        periodUnit: updatedAssignment.period_unit,
        firstDate: moment(updatedAssignment.first_date),
      });
    }
  }, [isOpen, updatedAssignment]);

  const handleClose = () => {
    setAssignment({
      name: "",
      firstDate: null,
      description: "",
      period: 1,
      moveIfNotCompleted: false,
      periodUnit: 1,
    });
    close();
  };

  const handleCreateAssignment = () => {
    if (!updatedAssignment) {
      limboclient
        .call(
          `=>assignment_create ~ ${JSON.stringify({
            ...assignment,
            client: clientId,
            firstDate: moment(assignment.firstDate)
              .locale("ru")
              .format("DD.MM.YYYY"),
          })}`
        )
        .then((result) => {
          if (result.success === true) handleClose();

          if (result.success === false && result.error === "not_authorized") {
            handleClose();
            authService.logout();
          }
        });
    } else {
      limboclient
        .call(
          `=>assignment_update ~ ${JSON.stringify({
            id: assignment.id,
            update: {
              name: assignment.name,
              description: assignment.description,
              moveIfNotCompleted: assignment.moveIfNotCompleted,
              firstDate: moment(assignment.firstDate)
                .locale("ru")
                .format("DD.MM.YYYY"),
              period: assignment.period,
              periodUnit: assignment.periodUnit,
            },
          })}`
        )
        .then((result) => {
          if (result.success === true) handleClose();

          if (result.success === false && result.error === "not_authorized") {
            handleClose();
            authService.logout();
          }
        });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>
        {updatedAssignment
          ? "Редагування домашнього завдання"
          : "Додавання домашнього завдання"}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label='Назва завдання'
          id='name'
          value={assignment.name}
          onChange={(event) =>
            setAssignment({ ...assignment, name: event.target.value })
          }
          className='!mt-2'
          required
        />
        <TextField
          fullWidth
          label='Опис завдання'
          id='description'
          value={assignment.description}
          onChange={(event) =>
            setAssignment({ ...assignment, description: event.target.value })
          }
          className='!mt-5'
          required
        />
        <FormControlLabel
          control={
            <Switch
              checked={assignment.moveIfNotCompleted}
              onChange={(event) =>
                setAssignment({
                  ...assignment,
                  moveIfNotCompleted: event.target.checked,
                })
              }
              className='ml-5'
            />
          }
          label='Переносити домашнє завдання, якщо воно не було виконане*'
          labelPlacement='start'
          className='!mt-5'
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              label='Оберіть дату початку завдання *'
              value={assignment.firstDate}
              onChange={(newValue) =>
                setAssignment({ ...assignment, firstDate: newValue })
              }
              className='!mt-5 w-full'
            />
          </DemoContainer>
        </LocalizationProvider>
        <TextField
          fullWidth
          label={`Період повторюваності домашнього завдання, кожен ..... ${availablePeriodUnit
            .find((periodUnit) => periodUnit.value === assignment.periodUnit)
            ?.label.toLowerCase()}`}
          id='period'
          value={assignment.period}
          onChange={(event) =>
            setAssignment({ ...assignment, period: Number(event.target.value) })
          }
          className='!mt-5'
          required
        />
        <div className='flex items-center mt-5'>
          <p className='mr-5'>
            Значення періоду повторюваності домашнього завдання *
          </p>
          <Select
            size='small'
            value={assignment.periodUnit}
            input={<OutlinedInput />}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            renderValue={(selected) =>
              availablePeriodUnit.find(
                (periodUnit) => periodUnit.value === selected
              )?.label
            }
            onChange={(event) =>
              setAssignment({ ...assignment, periodUnit: event.target.value })
            }
          >
            {availablePeriodUnit?.map((periodUnit, index) => (
              <MenuItem
                value={periodUnit.value}
                key={index.toString()}
              >
                {periodUnit.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className='flex items-center justify-between mt-5'>
          <Button
            onClick={handleClose}
            color='error'
            variant='outlined'
            className='w-full'
          >
            Відмінити
          </Button>
          <Button
            onClick={handleCreateAssignment}
            variant='contained'
            className='w-full !ml-5'
          >
            {updatedAssignment
              ? "Оновити домашнє завдання"
              : "Створити домашнє завдання"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AssignmentModal;
