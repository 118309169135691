import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import moment from 'moment';
import styles from '../../../therapy-tests/index.module.scss';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';

const columns = [
  { id: 'name', label: 'Назва завдання' },
  { id: 'description', label: 'Опис завдання' },
  { id: 'isDone', label: 'Виконано' },
  { id: 'period', label: 'Періодичність' },
  { id: 'firstDate', label: 'Дата початку' },
];

const AssignmentsTable = ({ assignments, handleDeleteAssignment, handleEditAssignment }) => {
  const handleCellView = (value, columnType, assignment) => {
    switch (columnType) {
      case 'period': return <p>{assignment.period} {assignment.periodUnit === 1 ? 'дні' : 'тижні'}</p>
      case 'isDone': return value ? 'Так' : 'Ні';
      case 'firstDate': return moment(assignment.first_date || assignment.firstDate).locale('uk').format('LL');
      default: return value;
    }
  };

  return (
    <TableContainer sx={{ width: '100%', position: 'relative', marginBottom: '20px', marginTop: '10px' }}>
      <Table stickyHeader aria-labelledby="tableTitle" size={"small"}>
        <TableHead>
          <TableRow>
            {assignments.length > 0 && columns.map((item) => (
              <TableCell
                key={item.id}
                align="left"
                style={{ fontWeight: 'bold'}}
              >
                {item.label}
              </TableCell>
            ))}
            <TableCell
              key="action"
              align="right"
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {assignments?.map((row, index) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={index.toString()}
              >
                {columns?.map((column) => {
                  return (
                    <TableCell key={column.id} align="left" className={styles.rowItemContainer}>
                      {handleCellView(row[column.id], column.id, row)}
                    </TableCell>
                  );
                })}
                <TableCell key="edit" align="right" className={styles.rowItemContainer}>
                  <BorderColorTwoToneIcon
                    className="ml-2 cursor-pointer"
                    color="primary"
                    onClick={() => handleEditAssignment(row)}
                  />
                  <DeleteForeverTwoToneIcon
                    color="error"
                    className="ml-5 cursor-pointer"
                    onClick={() => handleDeleteAssignment(row.id)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default AssignmentsTable;