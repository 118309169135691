import React, { useEffect, useState } from 'react';
import './App.css';
import {
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import SignIn from './pages/auth/sign-in';
import SideBar from './components/main/side-bar';
import SignUp from './pages/auth/sign-up';
import client from './services/core/limboclient';
import Confirm from './pages/auth/confirm';
import TherapyTestsPage from './pages/therapy-tests';
import TherapyClientsPage from './pages/therapy-clients';
import TherapySchedulePage from './pages/therapy-schedule';
import TherapyAppointmentPage from './pages/therapy-appointment';
import TherapyAppointmentUnassignedPage from './pages/therapy-appointment-unassigned';
import AdminUsersPage from './pages/admin-users';
import AdminTherapistsPage from './pages/admin-therapists';
import AdminClientsPage from './pages/admin-new-clients';
import LogsPage from './pages/logs';
import ClientPage from './pages/client';
import SystemProfilePage from './pages/system-profile';
import clsx from 'clsx';
import ProfilePage from './pages/profile';
import 'moment/locale/uk';
import MoodtrackerPage from './pages/moodtracker';
import AdminTransactionPage from './pages/admin-transaction';
import EditTherapistPage from './pages/admin-therapist-profile';

const App = () => {
  const navigate = useNavigate();
  const isRemoveProfilePage = window.location.pathname === '/r/user/profile';
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    client.call(`=>user_isAuthorized~`).then(isAuthorizedResult => {
      if (isAuthorizedResult !== isAuthorized) setIsAuthorized(isAuthorizedResult);
      if (!isAuthorizedResult && !~window.location.href.indexOf('sign-up') && !~window.location.href.indexOf('confirm') && !isRemoveProfilePage) navigate('/r/sign-in');
      // if (isAuthorizedResult) {
      //   navigate('/r/test/index');
      // }
    });
  }, []);

  return (
    <div className="App">
      <div className="container">
        {!isRemoveProfilePage && (
          <div className="sidebar">
            <SideBar />
          </div>
        )}
        <div className={clsx('contentContainer', isRemoveProfilePage && '!p-0' )}>
          <Routes>
            <Route path="/r/calendar/setSchedule" element={<TherapySchedulePage />} />
            <Route path="/r/appointment/list" element={<TherapyAppointmentPage />} />
            <Route path="/r/client/list" element={<TherapyClientsPage />} />
            <Route path="/r/client/view" element={<ClientPage />} />
            <Route path="/r/profile" element={<ProfilePage />} />
            <Route path="/r/test/index" element={<TherapyTestsPage />} />
            <Route path="/r/user/profile" element={<SystemProfilePage />} />
            <Route path="/r/appointment/unassigned" element={<TherapyAppointmentUnassignedPage />} />
            <Route path="/r/admin/users" element={<AdminUsersPage />} />
            <Route path="/r/admin/logs" element={<LogsPage />} />
            <Route path="/r/admin/transaction" element={<AdminTransactionPage />} />
            <Route path="/r/moodtracker/moodmanager" element={<MoodtrackerPage />} />
            <Route path="/r/admin/therapists" element={<AdminTherapistsPage />} />
            <Route path="/r/admin/therapist" element={<EditTherapistPage />} />
            <Route path="/r/admin/clients" element={<AdminClientsPage />} />
            <Route path="/r/sign-in/:id" element={<SignIn />} />
            <Route path="/r/sign-in" element={<SignIn />} />
            <Route path="/r/sign-up" element={<SignUp />} />
            <Route path="/r/confirm" element={<Confirm />} />
            <Route path={'*'} element={<SignIn />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
