import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../sign-in/index.module.scss";
import { Box, Button, Link, Snackbar, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import limboClient from "../../../services/core/limboclient";

const Confirm = () => {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState({
    isOpen: false,
    alertType: "success",
    message: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const code = data.get("code");

    limboClient
      .call(`=>user_confirmRegister ~ ${JSON.stringify({ code })};`)
      .then((result) => {
        if (result.success === true) {
          navigate("/r/sign-in");
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message:
              "Реєстрація не підтверджена, спробуйте інший код, або трохи пізніше!",
          });
        }
      });
  };

  const resendCode = () => {
    limboClient.call(`=>user_sendConfirm~`).then((result) => {
      if (result.success === true) {
        setOpenSnackbar({
          isOpen: true,
          alertType: "warning",
          message:
            "Код було повторно надіслано на електронну адресу, перевірте поштову скриньку та спробуйте ще раз!",
        });
      } else {
        setOpenSnackbar({
          isOpen: true,
          alertType: "error",
          message:
            "Код не був надісланий, спробуйте підтвердити реєстрацію пізніше!",
        });
      }
    });
  };

  return (
    <div className='overflow-hidden'>
      <div className={styles.background} />
      <Box
        component='form'
        onSubmit={handleSubmit}
        className={styles.loginFormContainer}
      >
        <span className='block text-4xl font-bold text-gray mb-2 text-center'>
          Підтвердити реєстрацію в 4Help admin panel
        </span>
        <TextField
          margin='dense'
          id='code'
          label='Код'
          name='code'
          autoComplete='code'
          autoFocus
          required
          fullWidth
        />
        <Link
          href={"/r/sign-in"}
          variant='body2'
        >
          Повернутись до авторизації
        </Link>
        <div className='flex items-center justify-between mt-5'>
          <Button
            variant='outlined'
            onClick={resendCode}
          >
            Надіслати код повторно
          </Button>
          <Button
            variant='contained'
            className='!ml-5'
            type='submit'
          >
            Підтвердити реєстрацію
          </Button>
        </div>
        <Snackbar
          open={openSnackbar.isOpen}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
        >
          <MuiAlert
            onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
            severity={openSnackbar.alertType}
            sx={{ width: "100%" }}
          >
            {openSnackbar.message}
          </MuiAlert>
        </Snackbar>
      </Box>
    </div>
  );
};

export default Confirm;
