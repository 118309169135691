import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import client from "../../services/core/limboclient";
import {
  Chip,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import styles from "../therapy-tests/index.module.scss";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import clsx from "clsx";
import ClientModal, { convertToDateFormat } from "./components/client-modal";
import moment from "moment";

const columns = [
  { id: "name", label: "Ім'я" },
  { id: "createDate", label: "Дата створення" },
  { id: "alliances", label: "Спеціалісти" },
];

const availableStatusView = [
  { id: 1, label: "Active" },
  { id: 2, label: "Await" },
];

const AdminClientsPage = () => {
  const [clients, setClients] = useState([]);
  const [therapistList, setTherapistList] = useState([]);
  const [consultantList, setConsultantList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [therapistFilter, setTherapistFilter] = useState(null);
  const [consultantFilter, setConsultantFilter] = useState(null);
  const [therapistStatusFilter, setTherapistStatusFilter] = useState(null);
  const [consultantStatusFilter, setConsultantStatusFilter] = useState(null);
  const [isOpenClientModal, setIsOpenClientModal] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [distanceBottom, setDistanceBottom] = useState(0);

  const tableEl = useRef();

  const loadClients = useCallback(() => {
    if (!hasMore) return;
    setLoading(true);

    client
      .call(
        `=> client_getClients ~ ${JSON.stringify({
          phrase: searchQuery,
          therapist: {
            id: therapistFilter,
            status: therapistStatusFilter,
          },
          consultant: {
            id: consultantFilter,
            status: consultantStatusFilter,
          },
          page: page + 1,
        })}`
      )
      .then((result) => {
        setClients((prevClients) => [...prevClients, ...result]);
        setPage((prevPage) => prevPage + 1);
        setHasMore(result?.length !== 0); //FIXME:
        setLoading(false);
      });
  }, [
    searchQuery,
    therapistFilter,
    consultantFilter,
    therapistStatusFilter,
    consultantStatusFilter,
    page,
    hasMore,
  ]);

  const scrollListener = useCallback(() => {
    const bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (
      tableEl.current.scrollTop > bottom - distanceBottom &&
      hasMore &&
      !loading
    ) {
      loadClients();
    }
  }, [hasMore, loadClients, loading, distanceBottom]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef?.addEventListener("scroll", scrollListener);
    return () => {
      tableRef?.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  useEffect(() => {
    if (!isOpenClientModal) {
      client
        .call(
          `=> client_getClients ~ ${JSON.stringify({
            phrase: searchQuery,
            therapist: {
              id: therapistFilter,
              status: therapistStatusFilter,
            },
            consultant: {
              id: consultantFilter,
              status: consultantStatusFilter,
            },
            page: 1,
          })}`
        )
        .then((res) => {
          if (res) {
            setClients(res);
          }
        });

      setPage(1);
      setHasMore(true);
    }
  }, [
    searchQuery,
    isOpenClientModal,
    therapistFilter,
    consultantFilter,
    therapistStatusFilter,
    consultantStatusFilter,
  ]);

  useEffect(() => {
    client
      .call(
        `=> {
          "therapists": (therapist_list ~),
          "consultants": (consultant_list ~)
        }`
      )
      .then(({ therapists, consultants }) => {
        setTherapistList(therapists);
        setConsultantList(consultants);
      });
  }, []);

  const handleStatusView = (status) => {
    const statusMap = {
      await: (
        <Chip
          label={status}
          color='warning'
          className='capitalize'
        />
      ),
      active: (
        <Chip
          label={status}
          color='primary'
          className='capitalize'
        />
      ),
    };

    return statusMap[status?.toLowerCase()] || status;
  };

  const handleCellView = (value, columnType) => {
    switch (columnType) {
      case "createDate":
        return moment(new Date(value)).locale("uk").format("LLL");
      case "alliances": {
        if (!value) return;

        let vals = value.map((alliance) => {
          let specialist;
          const specialistType = alliance.type?.displayVal;
          const statusDisplayVal = alliance.status?.displayVal;

          if (specialistType === "therapist") {
            specialist = therapistList.find(
              (therapist) => therapist.id === alliance.id
            );
          } else if (specialistType === "consultant") {
            specialist = consultantList.find(
              (consultant) => consultant.id === alliance?.id
            );
          }

          return (
            <div
              key={specialist?.id}
              className='flex items-center space-x-2'
            >
              {statusDisplayVal && handleStatusView(statusDisplayVal)}

              <span>
                {specialist?.firstName ?? ""} {specialist?.lastName ?? ""}
              </span>
            </div>
          );
        });

        return <div className='flex flex-col space-y-1'>{vals}</div>;
      }
      default:
        return value;
    }
  };

  const renderSearchBar = () => (
    <TextField
      variant='outlined'
      value={searchQuery}
      className='w-full lg:w-[500px]'
      onChange={(event) => setSearchQuery(event.target.value)}
      placeholder="Пошук за ім'ям або телефоном..."
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchTwoToneIcon color='primary' />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <ClearTwoToneIcon
              className='cursor-pointer'
              color='error'
              onClick={() => (setSearchQuery(""), setPage(1))}
            />
          </InputAdornment>
        ),
      }}
    />
  );

  const renderFilter = (
    label,
    filterValue,
    setFilterValue,
    list,
    renderItem
  ) => {
    return (
      <div className='flex flex-col mb-4 lg:mb-0 lg:w-[100%]'>
        <div className='flex items-center justify-between'>
          <p className='text-sm opacity-60'>{label}:</p>
          {filterValue && (
            <ClearTwoToneIcon
              fontSize='small'
              className='cursor-pointer'
              color='error'
              onClick={() => (setFilterValue(null), setPage(1))}
            />
          )}
        </div>
        <FormControl variant='standard'>
          <Select
            value={filterValue}
            input={<OutlinedInput />}
            displayEmpty
            className='!w-full !bg-white'
            renderValue={() =>
              renderItem(list.find((item) => item.id === filterValue))
            }
            onChange={(event) => (
              setFilterValue(event.target.value), setPage(1)
            )}
          >
            <MenuItem value='none'>Без {label.toLowerCase()}</MenuItem>
            {list.map((item) => (
              <MenuItem
                value={item?.id}
                key={item?.id}
              >
                {item?.label} {item?.firstName} {item?.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  return (
    <div className='p-4 lg:p-8'>
      <div className='flex flex-col lg:flex-row justify-between mb-6'>
        {renderSearchBar()}
        <div className='flex flex-col lg:flex-row lg:space-x-8 w-full lg:w-auto'>
          <div className='flex flex-col lg:w-[50%]'>
            <p className='font-bold mb-2 min-w-[250px]'>
              Фільтрація за терапевтом:
            </p>
            {renderFilter(
              "Терапевт",
              therapistFilter,
              setTherapistFilter,
              therapistList,
              (therapist) =>
                therapist ? (
                  <p>
                    {therapist?.firstName} {therapist?.lastName}
                  </p>
                ) : (
                  <em className='opacity-70'>Оберіть терапевта</em>
                )
            )}
            {renderFilter(
              "Статус",
              therapistStatusFilter,
              setTherapistStatusFilter,
              availableStatusView,
              (status) =>
                status ? (
                  handleStatusView(status?.label)
                ) : (
                  <em className='opacity-70'>Оберіть статус</em>
                )
            )}
          </div>
          <div className='flex flex-col lg:w-[50%] mt-4 lg:mt-0'>
            <p className='font-bold mb-2 min-w-[250px]'>
              Фільтрація за консультантом:
            </p>
            {renderFilter(
              "Консультант",
              consultantFilter,
              setConsultantFilter,
              consultantList,
              (consultant) =>
                consultant ? (
                  <p>
                    {consultant?.firstName} {consultant?.lastName}
                  </p>
                ) : (
                  <em className='opacity-70'>Оберіть консультанта</em>
                )
            )}
            {renderFilter(
              "Статус",
              consultantStatusFilter,
              setConsultantStatusFilter,
              availableStatusView,
              (status) =>
                status ? (
                  handleStatusView(status?.label)
                ) : (
                  <em className='opacity-70'>Оберіть статус</em>
                )
            )}
          </div>
        </div>
      </div>

      <TableContainer
        sx={{
          maxHeight: "70vh",
          width: "100%",
          position: "relative",
          marginTop: "20px",
        }}
        ref={tableEl}
      >
        <Table
          stickyHeader
          aria-labelledby='tableTitle'
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {clients.length > 0 &&
                columns.map((item) => (
                  <TableCell
                    key={item.id}
                    align='left'
                    style={{ fontWeight: "bold" }}
                  >
                    {item.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                onClick={() => {
                  setSelectedClient(row);
                  setIsOpenClientModal(true);
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align='left'
                    className={clsx(styles.rowItemContainer, "cursor-pointer")}
                  >
                    {handleCellView(row[column.id], column.id)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ClientModal
        isOpen={isOpenClientModal}
        onClose={() => setIsOpenClientModal(false)}
        selectedClientId={selectedClient?.id}
        therapistList={therapistList}
        consultantList={consultantList}
      />
    </div>
  );
};

export default AdminClientsPage;
