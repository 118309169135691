import React, { useEffect, useState } from "react";
import client from "../../services/core/limboclient";
import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "../therapy-tests/index.module.scss";
import BorderColorTwoToneIcon from "@mui/icons-material/BorderColorTwoTone";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import MoodModal from "./components/mood-modal";
import PostAddTwoToneIcon from "@mui/icons-material/PostAddTwoTone";

const columns = [
  { id: "name", label: "Назва настрою" },
  { id: "intensity", label: "Ступінь" },
  { id: "category", label: "Категорії настрою" },
];

export const handleMoodCategoryView = (category) => {
  switch (category) {
    case "Виснажений Неприємний":
      return (
        <Chip
          label={category}
          color='info'
          className='font-bold'
        />
      );
    case "Виснажений Приємний":
      return (
        <Chip
          label={category}
          color='success'
          className='font-bold'
        />
      );
    case "Eнергійний Приємний":
      return (
        <Chip
          label={category}
          sx={{ bgcolor: "#fcba03", color: "white" }}
          className='font-bold'
        />
      );
    case "Eнергійний Неприємний":
      return (
        <Chip
          label={category}
          color='error'
          className='font-bold'
        />
      );
    default:
      return <Chip label={category} />;
  }
};

const MoodtrackerPage = () => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [moodsList, setMoodsList] = useState([]);
  const [selectedMood, setSelectedMood] = useState(null);
  const [isOpenMoodModal, setIsOpenMoodModal] = useState(false);

  useEffect(() => {
    client
      .call(`=> mood_getCategories ~ `)
      .then((result) => setCategoriesList(result));
    client.call(`=> mood_getMoods ~ `).then((result) => setMoodsList(result));
  }, []);

  const handleCellView = (value, columnType) => {
    switch (columnType) {
      case "name":
        return value;
      case "category":
        return (
          <div className='grid grid-cols-2 gap-y-2 gap-x-2 w-max'>
            {handleMoodCategoryView(
              categoriesList.find((item) => item.id === value)?.name
            )}
          </div>
        );
      case "intensity":
        return (
          <div className='flex items-center tracking-widest py-1 px-3 bg-light-gray w-max rounded-2xl text-[#595959]'>
            <p className='text-sm font-bold'>{value ?? 0}</p>/
            <p className='text-xs tracking-normal'>10</p>
          </div>
        );
      default:
        return value;
    }
  };

  const handleCloseModal = () => {
    setIsOpenMoodModal(false);
    setSelectedMood(null);
    client.call(`=> mood_getMoods ~ `).then((result) => setMoodsList(result));
  };

  const handleDeleteMood = (id) => {
    client.call(`=> mood_delete ~ ${JSON.stringify({ id })}`);
    client.call(`=> mood_getMoods ~ `).then((result) => setMoodsList(result));
  };

  return (
    <div>
      <TableContainer
        sx={{
          maxHeight: "60vh",
          width: "100%",
          position: "relative",
          marginBottom: "20px",
        }}
      >
        <Table
          stickyHeader
          aria-labelledby='tableTitle'
          size={"small"}
        >
          <TableHead>
            <TableRow>
              {moodsList?.length > 0 &&
                columns.map((item) => (
                  <TableCell
                    key={item?.id}
                    align='left'
                    style={{ fontWeight: "bold" }}
                  >
                    {item?.label}
                  </TableCell>
                ))}
              <TableCell
                key='action'
                align='left'
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {moodsList?.map((row, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index.toString()}
                >
                  {columns?.map((column) => {
                    return (
                      <TableCell
                        key={column.id}
                        align='left'
                        className={styles.rowItemContainer}
                      >
                        {handleCellView(row[column.id], column.id)}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    key='edit'
                    align='right'
                    className={styles.rowItemContainer}
                  >
                    <BorderColorTwoToneIcon
                      className='ml-2 cursor-pointer'
                      color='primary'
                      onClick={() => {
                        setSelectedMood(row);
                        setIsOpenMoodModal(true);
                      }}
                    />
                    <DeleteForeverTwoToneIcon
                      color='error'
                      className='ml-5 cursor-pointer'
                      onClick={() => handleDeleteMood(row?.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant='contained'
        color='info'
        onClick={() => setIsOpenMoodModal(true)}
        className='!w-[300px] flex items-center'
      >
        <PostAddTwoToneIcon
          color='inherit'
          className='mr-2'
        />
        Додати новий настрій
      </Button>
      <MoodModal
        isOpen={isOpenMoodModal}
        onClose={handleCloseModal}
        selectedMood={selectedMood}
        categoriesList={categoriesList}
      />
    </div>
  );
};

export default MoodtrackerPage;
