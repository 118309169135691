import React, { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import client from "../../../../services/core/limboclient";
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryScatter,
  VictoryLabel,
} from "victory";
import moment from "moment";

const columns = [
  { id: "date", label: "Дата/час проходження тесту" },
  { id: "point", label: "Оцінка" },
];

const PassedTestModal = ({ isOpen, test, onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [testResults, setTestResults] = useState({});
  const [expanded, setExpanded] = useState(null);

  const chartData = useMemo(
    () =>
      test?.passes.map((item, i) => {
        const dateArr = item.date.split(".");
        return {
          point: item.sum,
          date: moment(
            dateArr[2] + "-" + dateArr[1] + "-" + (dateArr[0] - i),
            "YYYY-MM-DD"
          ).toDate(),
          id: item.id,
        };
      }),
    [test]
  );

  const handleCellView = (value, columnType) => {
    switch (columnType) {
      case "date":
        return moment(new Date(value)).locale("uk").format("LL");
      default:
        return value;
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleRowClick = (id) => {
    if (testResults[id]) {
      setExpanded(expanded === id ? null : id);
    } else {
      client
        .call(`=>therapyTest_getTestResult ~ {"id" : "${id}"}`)
        .then((result) => {
          setTestResults((prevResults) => ({ ...prevResults, [id]: result }));
          setExpanded(id);
        });
    }
  };

  return (
    test &&
    chartData && (
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle className='!font-bold'>
          {`Дані проходження користувачем тесту - "${test?.testName}"`}
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label='test data tabs'
          >
            <Tab label='Графік' />
            <Tab label='Інформація про тест' />
          </Tabs>
          <Box hidden={tabIndex !== 0}>
            <VictoryChart
              domain={{
                y: [
                  0,
                  Math.max(
                    chartData?.reduce(
                      (max, obj) => (obj.point > max ? obj.point : max),
                      -Infinity
                    )
                  ) + 4,
                ],
              }}
              padding={{ top: 20, bottom: 80, left: 30, right: 20 }}
            >
              <VictoryAxis
                dependentAxis
                style={{
                  grid: { stroke: "rgba(0, 0, 0, 0.1)" },
                  axis: { stroke: "red" },
                  tickLabels: { fontSize: 10 },
                }}
              />
              <VictoryAxis
                style={{
                  axis: { stroke: "blue" },
                }}
                tickLabelComponent={
                  <VictoryLabel
                    angle={90}
                    textAnchor='start'
                    verticalAnchor='middle'
                    style={{ fontSize: 10 }}
                  />
                }
                labelPlacement='perpendicular'
              />
              {chartData.length === 1 ? (
                <VictoryScatter
                  data={chartData.map((item) => ({
                    x: moment(item.date).format("DD/MM/YY"),
                    y: item.point,
                  }))}
                  size={5}
                  style={{
                    data: { fill: "#8884d8" },
                    labels: { fontSize: 10 },
                  }}
                  labels={({ datum }) => datum.y}
                />
              ) : (
                <VictoryLine
                  interpolation='natural'
                  data={chartData.map((item) => ({
                    x: moment(item.date).format("DD/MM/YY"),
                    y: item.point,
                  }))}
                  style={{
                    data: { stroke: "#8884d8" },
                  }}
                  labels={({ datum }) => datum.y}
                  labelComponent={
                    <VictoryLabel
                      dx={10}
                      textAnchor='start'
                      verticalAnchor='middle'
                      style={[{ fill: "green", fontSize: 10, fontWeight: 700 }]}
                    />
                  }
                />
              )}
            </VictoryChart>
          </Box>
          <Box hidden={tabIndex !== 1}>
            <TableContainer
              sx={{ width: "100%", position: "relative", marginBottom: "20px" }}
            >
              <Table
                stickyHeader
                aria-labelledby='tableTitle'
                size={"small"}
              >
                <TableBody>
                  {chartData?.map((row, index) => (
                    <Accordion
                      key={index.toString()}
                      expanded={expanded === row.id}
                      onChange={() => handleRowClick(row.id)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <TableRow
                          hover
                          tabIndex={-1}
                          style={{ width: "100%" }}
                        >
                          {columns?.map((column) => (
                            <TableCell
                              key={column.id}
                              style={{ width: "100%" }}
                            >
                              {handleCellView(row[column.id], column.id)}
                            </TableCell>
                          ))}
                        </TableRow>
                      </AccordionSummary>
                      <AccordionDetails>
                        {testResults[row?.id] &&
                          testResults[row?.id].map((answer, answerIndex) => (
                            <Box
                              key={answerIndex}
                              mb={2}
                            >
                              <Typography variant='body2'>{`Питання: ${answer.question}`}</Typography>
                              <Typography variant='body2'>{`Відповідь: ${answer.val}`}</Typography>
                              <Typography variant='body2'>{`Бали: ${answer.points}`}</Typography>
                            </Box>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
      </Dialog>
    )
  );
};

export default PassedTestModal;
