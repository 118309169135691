import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import LibraryAddTwoToneIcon from "@mui/icons-material/LibraryAddTwoTone";
import testService from "../../../../services/testService";
import { useNavigate } from "react-router-dom";

const UpdateTestModal = ({ isOpen, close, selectedTest }) => {
  const navigate = useNavigate();
  const [prevUpdatedTest, setPrevUpdatedTest] = useState(null);
  const [updatedTest, setUpdatedTest] = useState(selectedTest);
  const canDeleteQuestion =
    updatedTest?.questions?.filter((item) => item !== null)?.length > 1;

  useEffect(() => {
    if (selectedTest) {
      setUpdatedTest(selectedTest);

      (async () => {
        const test = await testService.getTestDetails(selectedTest?.id, () =>
          navigate("/r/sign-in")
        );
        setUpdatedTest(test);
        setPrevUpdatedTest(test);
      })();
    }
  }, [selectedTest]);

  const handleUpdateTest = async () => {
    const removedQuestions = prevUpdatedTest.questions
      .map((question) => {
        const isExistQuestionInUpdatedTest = updatedTest.questions?.find(
          (updatedQuestion) => {
            if (updatedQuestion?.id && updatedQuestion.id === question.id)
              return updatedQuestion;

            return null;
          }
        );
        if (!isExistQuestionInUpdatedTest) return question.id;
        return null;
      })
      .filter((item) => item !== null);

    const removedOptions = prevUpdatedTest.questions
      ?.map((question) => {
        const updatedQuestions = updatedTest.questions?.find(
          (updatedQuestion) => updatedQuestion?.id === question.id
        );

        if (!updatedQuestions) return question.options?.map((item) => item.id);

        if (updatedQuestions) {
          const removedOptionsInQuestion = question.options?.map((item) => {
            const isExistOption = updatedQuestions?.options?.find(
              (updatedOption) => updatedOption?.id === item.id
            );

            if (!isExistOption) return item.id;

            return null;
          });

          return removedOptionsInQuestion?.filter((item) => item !== null);
        }

        return null;
      })
      .filter((item) => item !== null)
      .flat(1)
      .filter((item) => item !== null);

    const addedQuestions = updatedTest.questions
      ?.map((question) => {
        const optionsList = question?.options?.map((option) => {
          return { text: option?.text, points: option?.points };
        });

        if (!question?.id)
          return {
            text: question?.text,
            options: optionsList,
          };

        return null;
      })
      .filter((item) => item !== null);

    const addedOptions = updatedTest.questions
      .map((question) => {
        if (!question?.id) return null;
        const newOptionsInQuestion = question?.options?.filter(
          (option) => option?.id === "new"
        );

        return newOptionsInQuestion.map((item) => {
          return {
            text: item.text,
            points: item.points,
            question: question.id,
          };
        });
      })
      .filter((item) => item !== null)
      .flat(1);

    const updatedQuestions = updatedTest.questions
      .map((question) => {
        if (question?.changed && question?.id)
          return { id: question.id, text: question.val };

        return null;
      })
      .filter((item) => item !== null);

    const updatedOptions = updatedTest.questions
      .map((question) => {
        if (!question) return null;

        return question.options
          .map((option) => {
            if (option?.changed && option.id !== "new")
              return { text: option.val, id: option.id, points: option.points };

            return null;
          })
          .filter((item) => item !== null);
      })
      .filter((item) => item !== null)
      .flat(1);

    const config = {
      locale: "ua",
      id: updatedTest.id,
      remove: {
        questions: removedQuestions,
        options: removedOptions,
        transcripts: [],
      },
      add: {
        questions: addedQuestions,
        options: addedOptions,
        transcripts: [],
      },
      edit: {
        questions: updatedQuestions,
        options: updatedOptions,
        transcripts: [],
        test: [
          {
            id: updatedTest.id,
            text: updatedTest.name,
            details: updatedTest.details,
            isPublic: !!updatedTest.public,
            daysToReload: updatedTest?.daysToReload ?? 0,
          },
        ],
      },
    };

    try {
      await testService.updateTest(config);
      close();
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddQuestion = () => {
    const newEmptyQuestion = {
      text: "",
      options: [{ text: "", points: 0, id: "new" }],
    };

    setUpdatedTest({
      ...updatedTest,
      questions: [...updatedTest?.questions, newEmptyQuestion],
    });
  };

  const handleDeleteQuestion = (question, questionIndex) => {
    if (!canDeleteQuestion) return;

    const updatedQuestionList = updatedTest?.questions?.map((item, index) => {
      if (index === questionIndex) return null;
      return item;
    });

    setUpdatedTest({ ...updatedTest, questions: [...updatedQuestionList] });
  };

  const changeQuestionName = (questionIndex, newName, question) => {
    const questions = updatedTest?.questions;
    const selectedQuestion = updatedTest?.questions[questionIndex];
    questions[questionIndex] = {
      ...selectedQuestion,
      val: newName,
      text: newName,
      changed: true,
    };

    setUpdatedTest({ ...updatedTest, questions });
  };

  const addOptionToQuestion = (questionIndex) => {
    const questions = updatedTest?.questions;
    const selectedQuestion = updatedTest?.questions[questionIndex];
    questions[questionIndex] = {
      ...selectedQuestion,
      options: [
        ...selectedQuestion?.options,
        { text: "", val: "", points: 0, id: "new" },
      ],
    };

    setUpdatedTest({ ...updatedTest, questions });
  };

  const changeOptionInQuestion = (
    questionIndex,
    optionIndex,
    optionText,
    optionPoint
  ) => {
    const updatedQuestionsData = updatedTest?.questions?.map((item, index) => {
      if (index === questionIndex) {
        const updatedOptions = item?.options?.map((option, answerIndex) => {
          if (answerIndex === optionIndex)
            return {
              ...option,
              val: optionText,
              text: optionText,
              points: optionPoint,
              changed: true,
            };

          return option;
        });

        return { ...item, options: updatedOptions };
      }

      return item;
    });

    setUpdatedTest({ ...updatedTest, questions: updatedQuestionsData });
  };

  const deleteOptionInQuestion = (option, questionIndex, optionIndex) => {
    const updatedQuestionsData = updatedTest?.questions?.map((item, index) => {
      if (index === questionIndex) {
        const updatedOptions = item?.options?.map((option, answerIndex) => {
          if (answerIndex === optionIndex) return null;
          return option;
        });

        return { ...item, options: updatedOptions };
      }

      return item;
    });

    setUpdatedTest({ ...updatedTest, questions: updatedQuestionsData });
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>{`Редагування тесту - "${selectedTest?.name}"`}</DialogTitle>
      <DialogContent>
        <TextField
          value={updatedTest?.name}
          label='Назва тесту'
          variant='outlined'
          onChange={(event) =>
            setUpdatedTest({ ...updatedTest, name: event.target.value })
          }
          className='w-[100%] !mt-2'
          required
        />
        <TextField
          value={updatedTest?.details}
          label='Опис тесту'
          variant='outlined'
          onChange={(event) =>
            setUpdatedTest({ ...updatedTest, details: event.target.value })
          }
          className='w-[100%] !mt-5'
          required
        />
        <TextField
          value={updatedTest?.daysToReload?.toString() ?? "0"}
          label='Кількість днів, скільки тест буде не доступний клієнту після проходження'
          variant='outlined'
          type='number'
          onChange={(event) =>
            setUpdatedTest({
              ...updatedTest,
              daysToReload: Number(event.target.value),
            })
          }
          sx={{
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          className='w-[100%] !mt-5'
        />
        <FormControlLabel
          control={
            <Switch
              checked={updatedTest?.public}
              onChange={(event) =>
                setUpdatedTest({
                  ...updatedTest,
                  public: event.target.checked,
                })
              }
            />
          }
          label='Тест буде доступний для клієнтів без призначення'
          labelPlacement='start'
          className='!mt-5 !ml-0'
        />
        <div className='mb-5 mt-5 flex items-center justify-between'>
          {updatedTest?.questions?.length > 0 && (
            <p className='font-bold italic'>Питання та відповіді: </p>
          )}
          <Button
            fullWidth
            variant='outlined'
            color='success'
            className='!w-max'
            onClick={() => handleAddQuestion()}
          >
            <LibraryAddTwoToneIcon
              color='success'
              className='mr-3'
            />
            Додати питання
          </Button>
        </div>
        <div>
          {updatedTest?.questions?.length > 0 &&
            updatedTest?.questions?.map((item, index) => {
              const canDeleteOptions =
                item?.options?.filter((item) => item !== null).length > 1;
              if (!item) return;

              return (
                <div className='mt-5 mb-5 border border-[#a1a1a1] rounded-lg p-5'>
                  <div className='flex items-center'>
                    <TextField
                      key={item?.id}
                      id={item?.id}
                      value={item?.val}
                      variant='outlined'
                      className='w-[100%] !mt-2'
                      multiline
                      maxRows={3}
                      onChange={(event) =>
                        changeQuestionName(index, event.target.value, item)
                      }
                    />
                    <Tooltip
                      title='Додати варіант'
                      placement='top'
                    >
                      <LibraryAddTwoToneIcon
                        color='primary'
                        className='ml-3 cursor-pointer'
                        onClick={() =>
                          addOptionToQuestion(
                            updatedTest.questions.indexOf(item)
                          )
                        }
                      />
                    </Tooltip>
                    <Tooltip
                      title='Видалити питання'
                      placement='top'
                    >
                      <HighlightOffTwoToneIcon
                        color={canDeleteQuestion ? "error" : "disabled"}
                        className='ml-3 cursor-pointer'
                        onClick={() =>
                          handleDeleteQuestion(
                            item,
                            updatedTest.questions.indexOf(item)
                          )
                        }
                      />
                    </Tooltip>
                  </div>
                  <div className='grid grid-cols-1 gap-5 mt-5'>
                    {item?.options?.map((answer, answerIndex) => {
                      if (!answer) return;

                      return (
                        <div className='flex items-center'>
                          <TextField
                            hiddenLabel
                            key={answer?.id}
                            value={answer?.val}
                            variant='filled'
                            className='w-[100%]'
                            onChange={(event) =>
                              changeOptionInQuestion(
                                index,
                                answerIndex,
                                event.target.value,
                                answer.points
                              )
                            }
                          />
                          <TextField
                            hiddenLabel
                            key={index.toString()}
                            value={answer?.points}
                            variant='filled'
                            className='w-[100px] !ml-5'
                            onChange={(event) =>
                              changeOptionInQuestion(
                                index,
                                answerIndex,
                                answer.text,
                                event.target.value
                              )
                            }
                          />
                          <Tooltip
                            title='Видалити варіант відповіді'
                            placement='top'
                          >
                            <HighlightOffTwoToneIcon
                              color={canDeleteOptions ? "error" : "disabled"}
                              className='ml-3 cursor-pointer'
                              onClick={() => {
                                if (canDeleteOptions)
                                  deleteOptionInQuestion(
                                    answer,
                                    index,
                                    answerIndex
                                  );
                              }}
                            />
                          </Tooltip>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
        <div className='grid grid-cols-2 gap-10'>
          <Button
            fullWidth
            variant='outlined'
            color='warning'
            onClick={close}
          >
            Відмінити зміни
          </Button>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={handleUpdateTest}
          >
            Зберегти зміни
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateTestModal;
