import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import limbo from "../../../../services/core/limboclient";
import moment from "moment";

const InviteClientDialog = ({ isOpen, onClose }) => {
  const [newInvitedClient, setNewInvitedClient] = useState({
    email: "",
    expirationDate: "",
  });

  const handleClose = () => {
    setNewInvitedClient({
      email: "",
      expirationDate: "",
    });
    onClose();
  };

  const handleSendInvite = () => {
    limbo
      .call(
        `=>therapist_createInvite~${JSON.stringify({
          email: newInvitedClient.email,
          expirationDate: newInvitedClient.expirationDate
            ? moment(new Date(newInvitedClient.expirationDate)).format(
                "DD.MM.YYYY"
              )
            : null,
        })}`
      )
      .then((result) => {
        if (result.success === true) {
          handleClose();
        }
      });
  };

  const validateEmail = (email) => {
    const emailRegex = /^([^\s@]+@[^\s@]+\.[^\s@]+)?$/;

    return emailRegex.test(email);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>Запросити нового клієнта</DialogTitle>
      <DialogContent>
        <TextField
          value={newInvitedClient?.email}
          label='Email*'
          variant='outlined'
          onChange={(event) =>
            setNewInvitedClient({
              ...newInvitedClient,
              email: event.target.value,
            })
          }
          className='w-[100%] !mt-2'
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              label='Дата закінчення дії запрошення'
              value={setNewInvitedClient.expirationDate}
              onChange={(newValue) =>
                setNewInvitedClient({
                  ...newInvitedClient,
                  expirationDate: newValue,
                })
              }
              className='w-full !mt-5'
              format='DD.MM.YYYY'
            />
          </DemoContainer>
        </LocalizationProvider>
        <div className='grid grid-cols-2 gap-10 mt-5'>
          <Button
            fullWidth
            variant='outlined'
            color='error'
            onClick={handleClose}
          >
            Відмінити запрошення
          </Button>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={handleSendInvite}
            disabled={!validateEmail(newInvitedClient?.email)}
          >
            Надіслати запрошення
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InviteClientDialog;
