import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "../../../therapy-tests/index.module.scss";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";

const columns = [{ id: "testName", label: "Назва тесту" }];

const PassedTestsTable = ({ tests, handleView }) => {
  return (
    <TableContainer
      sx={{
        width: "100%",
        position: "relative",
        marginBottom: "20px",
        marginTop: "10px",
      }}
    >
      <Table
        stickyHeader
        aria-labelledby='tableTitle'
        size={"small"}
      >
        <TableHead>
          <TableRow>
            {tests.length > 0 &&
              columns.map((item) => (
                <TableCell
                  key={item.id}
                  align='left'
                  style={{ fontWeight: "bold" }}
                >
                  {item.label}
                </TableCell>
              ))}
            <TableCell
              key='action'
              align='right'
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {tests?.map((row, index) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={index.toString()}
              >
                {columns?.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      align='left'
                      className={styles.rowItemContainer}
                    >
                      {row[column.id]}
                    </TableCell>
                  );
                })}
                <TableCell
                  key='edit'
                  align='right'
                  className={styles.rowItemContainer}
                >
                  <RemoveRedEyeTwoToneIcon
                    onClick={() => handleView(row)}
                    className='cursor-pointer'
                    color='warning'
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PassedTestsTable;
