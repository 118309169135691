import React from "react";
import { Dialog, DialogTitle, DialogContent, MenuItem } from "@mui/material";

const NewTestModal = ({
  isOpen,
  onClose,
  availableTests,
  handleAssignTest,
  assignedTests,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth='sm'
    >
      <DialogTitle className='!font-bold'>
        Оберіть тест, який хочете призначити клієнту
      </DialogTitle>
      <DialogContent>
        {availableTests?.map((test, index) => {
          const isExist = assignedTests?.find(
            (item) => item.testId === test.id
          );

          if (isExist) return null;

          return (
            <MenuItem
              value={test.id}
              key={index.toString()}
              onClick={() => handleAssignTest(test.id)}
            >
              {test.name}
            </MenuItem>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

export default NewTestModal;
