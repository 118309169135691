import React, { useEffect, useState, useCallback, useRef } from "react";
import client from "../../services/core/limboclient";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Snackbar,
  TextField,
  Button,
} from "@mui/material";

const AdminUsersPage = () => {
  const [availableRoles, setAvailableRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    isOpen: false,
    alertType: "success",
    message: "",
  });
  const containerRef = useRef(null); // Ref to track the scrolling container

  useEffect(() => {
    client.call(`=>{"roles": (user_getRoles~)}`).then((result) => {
      setAvailableRoles(result.roles);
    });
    fetchUsers(1, searchQuery);
  }, [searchQuery]);

  const fetchUsers = (pageNum, query) => {
    setLoading(true);
    client
      .call(
        `=>{"users":(user_getUsers~{"page": ${pageNum}, "phrase": "${query}"})}`
      )
      .then((result) => {
        if (pageNum === 1) {
          setUsers(result?.users);
        } else {
          setUsers((prevUsers) => [...prevUsers, ...result?.users]);
        }
        setHasMore(result?.users?.length > 0);
        setLoading(false);
      });
  };

  const handleScroll = useCallback(() => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollHeight - scrollTop === clientHeight && hasMore && !loading) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchUsers(nextPage, searchQuery);
    }
  }, [page, searchQuery, hasMore, loading]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const findUserRoles = (sumOfWeights) => {
    const userRoles = [];
    const sortedRoles = availableRoles?.sort((a, b) => a.num - b.num);

    for (let i = sortedRoles.length - 1; i >= 0; i--) {
      const role = sortedRoles[i];
      const roleWeight = Math.floor(sumOfWeights / role.num);
      if (roleWeight > 0) {
        for (let j = 0; j < roleWeight; j++) {
          userRoles.push(role);
        }
        sumOfWeights -= roleWeight * role.num;
      }
    }
    return userRoles;
  };

  const handleRoleTitle = (roleName) => {
    switch (roleName) {
      case "client":
        return "Клієнт";
      case "therapist":
        return "Терапевт";
      case "coordinator":
        return "Координатор";
      case "manager":
        return "Менеджер";
      case "admin":
        return "Адміністратор";
      case "consultant":
        return "Консультант";
      default:
        return roleName;
    }
  };

  const handleChangeRole = async ({ user, checked, roleName }) => {
    try {
      await client.call({
        query: `
        =>user_${checked ? "addRole" : "removeRole"}~ {
          "user" : $user,
          "role" : $role
        }`,
        params: {
          user: user.id,
          role: roleName,
        },
      });

      fetchUsers(1, searchQuery);
      setOpenSnackbar({
        isOpen: true,
        alertType: "success",
        message: `Роль для ${user.firstName} ${user.lastName} була успішно оновлена!`,
      });
    } catch (e) {
      setOpenSnackbar({
        isOpen: true,
        alertType: "error",
        message: `Роль для ${user.firstName} ${user.lastName} НЕ була оновлена!`,
      });
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(1);
  };

  return (
    <div
      className='h-[calc(100vh-100px)] p-4 overflow-auto'
      ref={containerRef}
    >
      <div className='flex items-center border-b border-[#a1a1a1] pb-3 mb-4'>
        <TextField
          variant='outlined'
          placeholder='Пошук користувачів...'
          value={searchQuery}
          onChange={handleSearch}
          className='w-[400px] mr-4'
        />
        <Button
          variant='contained'
          onClick={() => fetchUsers(1, searchQuery)}
        >
          Пошук
        </Button>
      </div>
      <div className='flex items-center border-b border-[#a1a1a1] pb-3'>
        <b className='w-[200px]'>Ім'я</b>
        <b className='w-[200px]'>Прізвище</b>
        <b className='w-[350px]'>Email</b>
        <b className='w-[150px]'>Ролі</b>
      </div>
      <div>
        {users?.map((item, index) => {
          const usersRoles = findUserRoles(item.roles);

          return (
            <div
              key={index.toString()}
              className='flex items-center mt-4 pb-2 border-b'
            >
              <p className='w-[200px]'>{item.firstName}</p>
              <p className='w-[200px]'>{item.lastName}</p>
              <p className='w-[350px]'>{item.email}</p>
              <div className='grid grid-cols-2 gap-y-2 gap-x-4'>
                {availableRoles.map((role) => {
                  const selectedRole = usersRoles.find(
                    (userRole) => userRole.id === role.id
                  );

                  return (
                    <FormControlLabel
                      key={role.id}
                      control={
                        <Checkbox
                          checked={!!selectedRole}
                          onChange={(event) => {
                            handleChangeRole({
                              user: item,
                              checked: event.target.checked,
                              roleName: role.name,
                            });
                          }}
                          className='!p-0.5'
                        />
                      }
                      label={handleRoleTitle(role.name)}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
        {loading && <p className='text-center mt-4'>Loading...</p>}
      </div>
      <Snackbar
        open={openSnackbar.isOpen}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
      >
        <Alert
          onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
          severity={openSnackbar.alertType}
          sx={{ width: "100%" }}
        >
          {openSnackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AdminUsersPage;
