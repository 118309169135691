import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Drawer,
  AppBar,
  CssBaseline,
  Toolbar,
  List,
  Typography,
  Divider,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Disclosure } from "@headlessui/react";
import PsychologyTwoToneIcon from "@mui/icons-material/PsychologyTwoTone";
import PeopleTwoToneIcon from "@mui/icons-material/PeopleTwoTone";
import LogoutIcon from "@mui/icons-material/Logout";
import styles from "./index.module.scss";
import authService from "../../../services/authService";
import client from "../../../services/core/limboclient";
import PermIdentityTwoToneIcon from "@mui/icons-material/PermIdentityTwoTone";

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [navigateList, setNavigateList] = useState([]);
  const user = useMemo(() => {
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");

    return { firstName, lastName };
  }, [location]);

  useEffect(() => {
    (async () => {
      const isAuthorized = await client
        .call(`=>user_isAuthorized~`)
        .then((isAuthorized) => {
          return isAuthorized;
        });

      if (isAuthorized) {
        setNavigateList(
          await authService.getDashboardList(() => navigate("/r/sign-in"))
        );
      }
    })();
  }, [location]);

  const therapyIconView = (topicName) => {
    switch (topicName) {
      case "Терапія":
        return (
          <PsychologyTwoToneIcon
            fontSize='large'
            color='warning'
          />
        );
      case "Адміністрування":
        return (
          <PeopleTwoToneIcon
            fontSize='large'
            color='info'
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar className='bg-primary flex items-center justify-between'>
          <Typography
            variant='h5'
            noWrap
            component='div'
            className='flex items-center'
          >
            <img
              src='/icons/logo.svg'
              alt='logo'
            />
            <span className='block ml-2'>admin panel</span>
          </Typography>
          <Typography
            variant='h7'
            noWrap
            component='div'
            className='flex items-center'
          >
            <p
              className='mr-3 cursor-pointer'
              onClick={() => navigate("/r/profile")}
            >
              {user.firstName} {user.lastName}
            </p>
            <PermIdentityTwoToneIcon
              className='cursor-pointer mr-10'
              onClick={() => navigate("/r/profile")}
            />
            <span
              className='block mr-2 cursor-pointer'
              onClick={() => authService.logout(() => navigate("/r/sign-in"))}
            >
              Вийти
            </span>
            <LogoutIcon
              className='cursor-pointer'
              onClick={() => navigate("/r/sign-in")}
            />
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 240, boxSizing: "border-box" },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List className={styles.navigationList}>
            {navigateList?.length > 0 &&
              navigateList?.map((item, index) => (
                <Disclosure
                  key={index.toString()}
                  defaultOpen
                >
                  <Disclosure.Button
                    onClick={(event) => event.preventDefault()}
                  >
                    {therapyIconView(item.topicName)}
                    <span className={styles.menuItemTitle}>
                      {item?.topicName}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    {item?.elements?.map((item, index) => (
                      <Link
                        className={clsx(
                          styles.link,
                          location.pathname.includes(item?.route) &&
                            styles.openTab
                        )}
                        to={`..${item?.route}`}
                        key={index.toString()}
                      >
                        <ListItemButton>
                          <ListItemText primary={item?.displayName} />
                        </ListItemButton>
                      </Link>
                    ))}
                  </Disclosure.Panel>
                </Disclosure>
              ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </Box>
  );
};

export default SideBar;
