import React, { useEffect, useState } from "react";
import {
  Button,
  Snackbar,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Tooltip,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import client from "../../services/core/limboclient";
import CloseIcon from "@mui/icons-material/Close";
import AssignmentModal from "./components/assignment-modal";
import BorderColorTwoToneIcon from "@mui/icons-material/BorderColorTwoTone";
import EventBusyTwoToneIcon from "@mui/icons-material/EventBusyTwoTone";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MuiAlert from "@mui/material/Alert";
import authService from "../../services/authService";
import { useNavigate } from "react-router-dom";
import PassedTestModal from "./components/passed-test-modal";
import AssignmentsTable from "./components/assignments-table";
import PassedTestsTable from "./components/passed-tests-table";
import PrescriptionsTable from "./components/prescriptions-table";
import NewTestModal from "./components/new-test-modal";
import PaymentTransactionsTable from "./components/payment-transactions-table";
import MoodsTable from "./components/moods-table";
import SessionTab from "./components/session-tab";

const availableStatusView = [
  { id: 1, label: "Active" },
  { id: 2, label: "Await" },
];

const ClientPage = () => {
  const navigate = useNavigate();
  const url = new URLSearchParams(window.location.search);
  const clientId = url.get("id");
  const [clientData, setClientData] = useState(null);
  const [availableTests, setAvailableTests] = useState([]);
  const [paymentTransactions, setPaymentTransactions] = useState([]);
  const [moodsList, setMoodsList] = useState([]);
  const [moodsCategories, setMoodsCategories] = useState([]);
  const [moodsFrom, setMoodsFrom] = useState(null);
  const [moodsTo, setMoodsTo] = useState(null);
  const [assignments, setAssignments] = useState([]);
  const [selectedAssigmentDate, setSelectedAssignmentDate] = useState(null);
  const [isOpenAssignmentDialog, setIsOpenAssignmentDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    isOpen: false,
    alertType: "success",
    message: "",
  });
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [isUpdateClientName, setIsUpdateClientName] = useState(false);
  const [selectedPassedTest, setSelectedPassedTest] = useState(null);
  const [isOpenPassedTestModal, setIsOpenPassedTestModal] = useState(false);
  const [isOpenNewTestModal, setIsOpenNewTestModal] = useState(false);
  const [clientRate, setClientRate] = useState("");
  const [specialistShare, setSpecialistShare] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [clientStatus, setClientStatus] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (moodsFrom || moodsTo) {
      client
        .call(
          `=>mood_getRecords ~ ${JSON.stringify({
            client: clientId,
            ...(moodsFrom && { from: moment(moodsFrom).format("DD.MM.YYYY") }),
            ...(moodsTo && { to: moment(moodsTo).format("DD.MM.YYYY") }),
          })}`
        )
        .then((result) => setMoodsList(result));
    }
  }, [moodsFrom, moodsTo]);

  useEffect(() => {
    client
      .call({
        query: `
        $clientDetails = client_getMyClient ~ {
          "id" : $clientID
        };
        ? $clientDetails.success === false @{;
          =>$clientDetails;
        };
        =>{
          "clientDetails" : $clientDetails,
          "prescriptions" : (therapyTest_getPrescriptions ~ {
            "client" : $clientID
          }),
          "transactions" : (payment_getClientTransactions ~ {
            "client" : $clientID
          }),
          "moodCategories": (mood_getCategories ~),
          "moodList": (mood_getRecords ~ {
            "client" : $clientID
          }),
          "results" : (therapyTest_getTestResults ~ {
            "client" : $clientID
          }),
          "tests" : (therapyTest_getTestList ~),
          "assignments" : (assignment_getAssignments ~ {
            "client" : $clientID
          }),
          "notes": (client_getNotes ~ {
            "client" : $clientID
          })
        }
      `,
        params: {
          clientID: clientId,
        },
      })
      .then((result) => {
        setAvailableTests(result.tests);
        setMoodsList(result.moodList);
        setMoodsCategories(result.moodCategories);
        setClientEmail(result.clientDetails.email || "");
        setClientPhone(result.clientDetails.phone || "");
        setClientRate(result?.clientDetails?.rate);
        setSpecialistShare(result?.clientDetails?.specialistShare);
        setNotes(result.notes);
        setClientStatus(result.clientDetails?.status?.val || "");
        const testResults = result.results.reduce((res, val) => {
          res[val.test] = res[val.test] || {
            passes: [],
            testName: val.testName,
          };
          res[val.test].passes.push({
            id: val.id,
            date: val.date,
            sum: val.sum,
          });
          return res;
        }, {});
        setClientData({
          id: result.clientDetails.id,
          name: result.clientDetails.name,
          prescriptions: result.prescriptions,
          results: Object.keys(testResults).map((key) => ({
            test: key,
            testName: testResults[key].testName,
            passes: testResults[key].passes,
          })),
          alliance: result.clientDetails.alliance,
        });
        setAssignments(result.assignments);
        setPaymentTransactions(result.transactions);
      });
  }, [clientId]);

  useEffect(() => {
    if (!isOpenAssignmentDialog && !!selectedAssigmentDate) {
      client
        .call(
          `=>assignment_getDayAssignments~${JSON.stringify({
            client: clientId,
            date: moment(selectedAssigmentDate).format("DD.MM.YYYY"),
          })}`
        )
        .then((result) => {
          if (result.success === false && result.error === "not_authorized") {
            authService.logout(() => navigate("/r/sign-in"));
            return;
          }
          setAssignments(result);
        });

      setSelectedAssignment(null);
    }
  }, [clientId, isOpenAssignmentDialog, selectedAssigmentDate]);

  const handleAssignTest = async (testId) => {
    await client
      .call({
        query: `
        $prescribeRes = therapyTest_prescript ~ {
          "testID" : $testID,
          "clientID" : $clientID
        };
        ? $prescribeRes.success === false @{;
          =>$prescribeRes;
        } : @{;
          =>therapyTest_getPrescriptions ~ {
            "client" : $clientID
          };
        };
      `,
        params: {
          testID: testId,
          clientID: clientId,
        },
      })
      .then((result) => {
        if (result.success === false && result.error === "not_authorized") {
          authService.logout(() => navigate("/r/sign-in"));
          return;
        }

        if (result.success === false) {
          alert(result.error);
        } else {
          setClientData({ ...clientData, prescriptions: result });
        }

        setIsOpenNewTestModal(false);
      });
  };

  const handleDeleteAssignment = async (assignId) => {
    await client
      .call(`=>assignment_delete ~ ${JSON.stringify({ id: assignId })}`)
      .then((result) => {
        if (result.success === true) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Домашнє завдання було успішно видалено!",
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Домашнє завдання не було видалено, спробуйте ще раз!",
          });
        }
      });

    await client
      .call(
        `=>assignment_getAssignments~${JSON.stringify({ client: clientId })}`
      )
      .then((result) => {
        setAssignments(result);
      });
  };

  const handleUpdateClientName = (newName) => {
    setIsUpdateClientName(false);
    client
      .call(
        `=> client_updateMyClient ~ ${JSON.stringify({
          id: clientId,
          update: {
            name: newName,
          },
        })}`
      )
      .then((result) => {
        if (result.success === false && result.error === "not_authorized") {
          authService.logout(() => navigate("/r/sign-in"));
          return;
        }

        if (result.success === true) {
          setClientData({ ...clientData, name: newName });
          setIsUpdateClientName(false);
        }
      });
  };

  const handleUpdateSpecialistShare = () => {
    client
      .call(
        `=> client_updateMyClient ~ ${JSON.stringify({
          id: clientId,
          update: {
            ...(specialistShare && { share: specialistShare }),
          },
        })}`
      )
      .then((result) => {
        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Долю терапевта було успішно оновлено!",
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Долю терапевта не було оновлено, спробуйте ще раз!",
          });
        }
      })
      .catch((error) => {
        console.error("Error updating client:", error);
        setOpenSnackbar({
          isOpen: true,
          alertType: "error",
          message: "Сталася помилка, спробуйте ще раз!",
        });
      });
  };

  const handleUpdateSpecialistRate = () => {
    client
      .call(
        `=> client_updateMyClient ~ ${JSON.stringify({
          id: clientId,
          update: {
            ...(clientRate && { rate: clientRate }),
          },
        })}`
      )
      .then((result) => {
        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Тариф було успішно оновлено!",
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Тариф не було оновлено, спробуйте ще раз!",
          });
        }
      })
      .catch((error) => {
        console.error("Error updating client:", error);
        setOpenSnackbar({
          isOpen: true,
          alertType: "error",
          message: "Сталася помилка, спробуйте ще раз!",
        });
      });
  };

  const handleUpdateClientEmail = () => {
    client
      .call(
        `=> client_updateMyClient ~ ${JSON.stringify({
          id: clientId,
          update: {
            ...(clientEmail && { email: clientEmail }),
          },
        })}`
      )
      .then((result) => {
        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Пошту було успішно оновлено!",
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Пошту не було оновлено, спробуйте ще раз!",
          });
        }
      })
      .catch((error) => {
        console.error("Error updating client:", error);
        setOpenSnackbar({
          isOpen: true,
          alertType: "error",
          message: "Сталася помилка, спробуйте ще раз!",
        });
      });
  };

  const handleUpdateClientPhone = () => {
    client
      .call(
        `=> client_updateMyClient ~ ${JSON.stringify({
          id: clientId,
          update: {
            ...(clientPhone && { phone: clientPhone }),
          },
        })}`
      )
      .then((result) => {
        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Телефон було успішно оновлено!",
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Телефон не було оновлено, спробуйте ще раз!",
          });
        }
      })
      .catch((error) => {
        console.error("Error updating client:", error);
        setOpenSnackbar({
          isOpen: true,
          alertType: "error",
          message: "Сталася помилка, спробуйте ще раз!",
        });
      });
  };

  const handleUpdateClientStatus = () => {
    client
      .call(
        `=> client_updateMyClient ~ ${JSON.stringify({
          id: clientId,
          update: {
            status: clientStatus,
          },
        })}`
      )
      .then((result) => {
        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Статус було успішно оновлено!",
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Статус не було оновлено, спробуйте ще раз!",
          });
        }
      })
      .catch((error) => {
        console.error("Error updating client:", error);
        setOpenSnackbar({
          isOpen: true,
          alertType: "error",
          message: "Сталася помилка, спробуйте ще раз!",
        });
      });
  };

  const handleDeletePrescription = (prescriptionId) => {
    client
      .call({
        query: `
        $deletedPrescription = therapyTest_deletePrescription ~ {
          "id" : $prescriptionId
        };
        ? $deletedPrescription.success === false @{;
          =>$deletedPrescription;
        };
        =>{
          "prescriptions" : (therapyTest_getPrescriptions ~ {
            "client" : $clientID
          })
        }
      `,
        params: {
          clientID: clientId,
          prescriptionId: prescriptionId,
        },
      })
      .then((result) => {
        if (result.prescriptions) {
          setClientData({
            ...clientData,
            prescriptions: result?.prescriptions,
          });
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Призначені тести були успішно оновлені!",
          });
        }
      });
  };

  const handleAddNewNote = async () => {
    if (newNote.length > 0) {
      await client
        .call(
          `=> client_addNote ~ ${JSON.stringify({
            client: clientId,
            note: newNote,
          })}`
        )
        .then((result) => {
          if (result.success) {
            setOpenSnackbar({
              isOpen: true,
              alertType: "success",
              message: "Нотатка була успішно додана!",
            });
            setNewNote("");
          } else {
            setOpenSnackbar({
              isOpen: true,
              alertType: "error",
              message: "Нотатка не була додана, спробуйте ще раз!",
            });
          }
        });

      const updatedNotes = await client.call(
        `=> client_getNotes ~ ${JSON.stringify({ client: clientId })}`
      );
      setNotes(updatedNotes);
    } else {
      setOpenSnackbar({
        isOpen: true,
        alertType: "error",
        message: "Нотатка не була додана, спробуйте ще раз!",
      });
    }
  };

  const handleDeleteNote = (noteId) => {
    client
      .call(
        `=>note_delete ~ ${JSON.stringify({
          id: noteId,
        })}`
      )
      .then((result) => {
        if (result.success) {
          client
            .call(
              `=> client_getNotes ~ ${JSON.stringify({ client: clientId })}`
            )
            .then((result) => {
              setNotes(result);
            });
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Нотатка була успішно видалена!",
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Нотатка не була видалена, спробуйте ще раз!",
          });
        }
      });
  };

  return (
    <div className='overflow-y-auto max-h-[calc(100vh-100px)] pr-5 pb-10'>
      <div className='flex items-center'>
        {!isUpdateClientName && (
          <p className='font-bold text-2xl'>{clientData?.name}</p>
        )}
        {!isUpdateClientName && (
          <BorderColorTwoToneIcon
            className='ml-5 cursor-pointer'
            fontSize='small'
            color='primary'
            onClick={() => {
              setIsUpdateClientName(true);
            }}
          />
        )}
        {isUpdateClientName && (
          <TextField
            autoFocus
            defaultValue={clientData?.name}
            onBlur={(event) => handleUpdateClientName(event.target.value)}
          />
        )}
      </div>
      <div className='flex items-center mt-3'>
        <p className='font-bold'>Тариф:</p>
        <TextField
          variant='outlined'
          type='number'
          placeholder='Тариф'
          value={clientRate?.toString()}
          onChange={(event) => setClientRate(Number(event.target.value))}
          onBlur={handleUpdateSpecialistRate}
          className='block !w-[150px] !ml-5'
          size='small'
          sx={{
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />
      </div>
      <div className='flex items-center mt-3'>
        <p className='font-bold'>Доля терапевта:</p>
        <TextField
          variant='outlined'
          type='number'
          placeholder='Доля терапевта'
          value={specialistShare?.toString()}
          onChange={(event) => setSpecialistShare(Number(event.target.value))}
          onBlur={handleUpdateSpecialistShare}
          className='block !w-[150px] !ml-5'
          size='small'
          sx={{
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />
      </div>
      <div className='flex items-center mt-3'>
        <p className='font-bold'>Емейл:</p>
        <TextField
          variant='outlined'
          type='text'
          placeholder='Емейл'
          value={clientEmail || ""}
          onChange={(event) => setClientEmail(event.target.value)}
          onBlur={handleUpdateClientEmail}
          className='block !w-[150px] !ml-5'
          size='small'
        />
      </div>
      <div className='flex items-center mt-3'>
        <p className='font-bold'>Телефон:</p>
        <TextField
          variant='outlined'
          type='text'
          placeholder='Телефон'
          value={clientPhone || ""}
          onChange={(event) => setClientPhone(event.target.value)}
          onBlur={handleUpdateClientPhone}
          className='block !w-[150px] !ml-5'
          size='small'
        />
      </div>
      {/* Status Section */}
      <div className='flex items-center mt-3'>
        <p className='font-bold'>Статус:</p>
        <FormControl
          variant='outlined'
          size='small'
          className='!ml-5'
          sx={{ minWidth: 150 }}
        >
          <InputLabel id='client-status-label'>Статус</InputLabel>
          <Select
            labelId='client-status-label'
            value={clientStatus}
            label='Статус'
            onChange={(event) => {
              setClientStatus(event.target.value);
            }}
            onBlur={handleUpdateClientStatus}
          >
            {availableStatusView.map((status) => (
              <MenuItem
                key={status.id}
                value={status.id}
              >
                <Chip
                  label={status.label}
                  color={
                    status.label.toLowerCase() === "active"
                      ? "success"
                      : status.label.toLowerCase() === "await"
                      ? "warning"
                      : "default"
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Box className='p-5 mt-5'>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
        >
          <Tab label='Нотатки' />
          <Tab label='Тести' />
          <Tab label='Домашні завдання' />
          <Tab label='Транзакції' />
          <Tab label='Сесії' />
          <Tab label='Настрій' />
        </Tabs>
        <Box p={5}>
          {activeTab === 0 && (
            <div className='mt-5'>
              <p className='font-bold text-xl mb-2'>Нотатки:</p>
              {notes?.length > 0 &&
                notes.map((item, index) => (
                  <div
                    key={index}
                    className='border border-[#a1a1a1] p-2 rounded-lg relative mb-3'
                  >
                    <Tooltip
                      title='Видалити нотатку'
                      placement='top-end'
                    >
                      <CloseIcon
                        color='error'
                        className='absolute top-2 right-2 cursor-pointer'
                        onClick={() => {
                          handleDeleteNote(item.id);
                        }}
                      />
                    </Tooltip>
                    <p className='w-[90%]'>{item.note}</p>
                    <p className='text-sm text-gray flex items-center justify-end mt-2'>
                      {moment(new Date(item.createDate))
                        .locale("uk")
                        .format("LL")}
                    </p>
                  </div>
                ))}
              <TextField
                value={newNote}
                placeholder='Нова нотатка...'
                variant='outlined'
                size='small'
                onChange={(event) => setNewNote(event.target.value)}
                className='w-[100%] !rounded-lg'
              />
              <Button
                variant='contained'
                className='!mt-5'
                onClick={handleAddNewNote}
              >
                Додати нотатку
              </Button>
            </div>
          )}
          {activeTab === 1 && (
            <>
              <div className='border border-[#a1a1a1] rounded-lg p-5 w-full mt-5'>
                <div className='flex items-center justify-between'>
                  <p className='font-bold text-xl'>Призначені тести</p>
                  <Button
                    onClick={() => setIsOpenNewTestModal(true)}
                    variant='contained'
                  >
                    Призначити новий тест
                  </Button>
                </div>
                {clientData?.prescriptions?.length > 0 && (
                  <PrescriptionsTable
                    tests={clientData?.prescriptions}
                    handleDeletePrescription={(id) =>
                      handleDeletePrescription(id)
                    }
                  />
                )}
                {clientData?.prescriptions?.length === 0 && (
                  <em className='opacity-70 text-xl mt-5 flex justify-center'>
                    Призначені тести відсутні...
                  </em>
                )}
              </div>
              <div className='border border-[#a1a1a1] rounded-lg p-5 w-full mt-5'>
                <p className='font-bold text-xl'>Пройдені тести</p>
                {clientData?.results?.length > 0 && (
                  <PassedTestsTable
                    tests={clientData?.results}
                    handleView={(test) => {
                      setSelectedPassedTest(test);
                      setIsOpenPassedTestModal(true);
                    }}
                  />
                )}
                {clientData?.results?.length === 0 && (
                  <em className='opacity-70 text-xl mt-5 flex justify-center'>
                    Пройдені тести відсутні...
                  </em>
                )}
              </div>
            </>
          )}
          {activeTab === 2 && (
            <div className='border border-[#a1a1a1] rounded-lg p-5 mt-5'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <p className='font-bold text-xl'>Домашнє завдання</p>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        label='Фільтрувати за датою виконання'
                        value={selectedAssigmentDate}
                        onChange={(newValue) =>
                          setSelectedAssignmentDate(newValue)
                        }
                        className='w-[300px] !ml-5'
                        format='DD.MM.YYYY'
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {selectedAssigmentDate && (
                    <EventBusyTwoToneIcon
                      color='error'
                      className='cursor-pointer !ml-5 !mt-2'
                      onClick={() => setSelectedAssignmentDate(null)}
                    />
                  )}
                </div>
                <div>
                  <Button
                    variant='contained'
                    onClick={() => setIsOpenAssignmentDialog(true)}
                  >
                    Додати домашнє завдання клієнту
                  </Button>
                </div>
              </div>
              {assignments?.length > 0 && (
                <AssignmentsTable
                  assignments={assignments}
                  handleDeleteAssignment={(id) => handleDeleteAssignment(id)}
                  handleEditAssignment={(assignment) => {
                    setSelectedAssignment(assignment);
                    setIsOpenAssignmentDialog(true);
                  }}
                />
              )}
              {assignments?.length === 0 && (
                <em className='opacity-70 text-xl mt-7 flex justify-center'>
                  Домашнє завдання відсутнє...
                </em>
              )}
            </div>
          )}
          {activeTab === 3 && (
            <div className='border border-[#a1a1a1] rounded-lg p-5 mt-5'>
              <p className='font-bold text-xl'>Платіжні транзакції</p>
              <div>
                {paymentTransactions?.length > 0 && (
                  <PaymentTransactionsTable
                    transactions={paymentTransactions}
                  />
                )}
                {paymentTransactions?.length === 0 && (
                  <em className='opacity-70 text-xl mt-7 flex justify-center'>
                    Платежі відсутні...
                  </em>
                )}
              </div>
            </div>
          )}
          {activeTab === 4 && clientData.alliance && (
            <SessionTab allianceID={clientData.alliance} />
          )}
          {activeTab === 5 && (
            <div className='border border-[#a1a1a1] rounded-lg p-5 mt-5'>
              <div className='flex items-center justify-between'>
                <p className='font-bold text-xl'>Дошка зміни настрою</p>
                <div className='flex items-center'>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        label='Фільтрувати від'
                        value={moodsFrom}
                        onChange={(newValue) => setMoodsFrom(newValue)}
                        className='w-[300px] !ml-5'
                        format='DD.MM.YYYY'
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        label='Фільтрувати до'
                        value={moodsTo}
                        onChange={(newValue) => setMoodsTo(newValue)}
                        className='w-[300px] !ml-5'
                        format='DD.MM.YYYY'
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div className='mt-[20px]'>
                {moodsList?.length > 0 && (
                  <MoodsTable
                    moodsList={moodsList}
                    moodsCategories={moodsCategories}
                  />
                )}
                {moodsList?.length === 0 && (
                  <em className='opacity-70 text-xl mt-7 flex justify-center'>
                    Відмітки настрою відсутні...
                  </em>
                )}
              </div>
            </div>
          )}
        </Box>
      </Box>

      <AssignmentModal
        isOpen={isOpenAssignmentDialog}
        close={() => {
          setIsOpenAssignmentDialog(false);
        }}
        clientId={clientId}
        updatedAssignment={selectedAssignment}
      />
      <PassedTestModal
        test={selectedPassedTest}
        isOpen={isOpenPassedTestModal}
        onClose={() => {
          setIsOpenPassedTestModal(false);
          setSelectedPassedTest(null);
        }}
      />

      <NewTestModal
        isOpen={isOpenNewTestModal}
        onClose={() => setIsOpenNewTestModal(false)}
        availableTests={availableTests}
        assignedTests={clientData?.prescriptions || []}
        handleAssignTest={(id) => handleAssignTest(id)}
      />
      <Snackbar
        open={openSnackbar.isOpen}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
          severity={openSnackbar.alertType}
          sx={{ width: "100%" }}
        >
          {openSnackbar.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ClientPage;
